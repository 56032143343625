export const leatherColorObjesByTanner = {
  northSteer: [
    { label: 'コルク', value: 'cork', color: '#CE7220' },
    { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
    { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
    { label: 'ブラック', value: 'black', color: '#1E1E1E' },
    { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
    { label: 'ティール', value: 'tealGreen', color: '#317290' },
    { label: 'ネイビー', value: 'navy', color: '#0d194a' }
  ]
}
export const softTannerObjs = [{ label: 'Nine States Leather', value: 'nineStates' }]

export const leatherColorObjsJa = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Dブラウン', value: 'dBrown', color: '#350816' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' }
]

const BINDING_COLORS_JA = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'Dブラウン', value: 'dBrown', color: '#350816' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' },
  { label: 'ホワイト', value: 'white', color: '#fff' }
]

const EUROPEAN_STEER_LEATHER_COLORS_JA = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Dブラウン', value: 'dBrown', color: '#350816' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' }
]

const NORTH_LEATHER_COLORS_JA = [
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Dブラウン', value: 'dBrown', color: '#350816' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'マディグラス', value: 'mardiGras', color: '#4e734c' },
  { label: 'ティール', value: 'tealGreen', color: '#317290' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'グレー', value: 'gray', color: '#8A8C8C' }
]

const STEER_LEATHER_COLORS_JA = [
  { label: 'コルク', value: 'cork', color: '#CE7220' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'ブラック', value: 'black', color: '#1E1E1E' },
  { label: 'Cブラウン', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'チョコレート', value: 'chocolate', color: '#6b3b36' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#0d194a' },
  { label: 'ブルー', value: 'blue', color: '#0d42b5' },
  { label: 'ホワイト', value: 'white', color: '#fff' }
]

export const LACES = [
  { label: 'グレー', value: 'gray', color: '#8A8C8C' },
  { label: 'ホワイト', value: 'white', color: '#fff' },
  { label: '中白ブラック', value: 'materialBlack', color: '#282925' },
  { label: 'ブラック', value: 'black', color: '#282925' },
  { label: 'レッドオレンジ', value: 'redOrange', color: '#FF362E' },
  { label: 'オレンジ', value: 'orange', color: '#FF6C23' },
  { label: 'イエロー', value: 'yellow', color: '#E8C84B' },
  { label: 'レモンイエロー', value: 'lemonYellow', color: '#D9D943' },
  { label: 'コルク', value: 'kurk', color: '#CE7220' },
  { label: 'チョコレート', value: 'chocolate', color: '#70352e' },
  { label: 'レッド', value: 'red', color: '#CB1527' },
  { label: 'ネイビー', value: 'navy', color: '#202742' },
  { label: 'ブルー', value: 'blue', color: '#193B85' },
  { label: 'グリーン', value: 'green', color: '#407D66' },
  { label: 'ベイラーブルー', value: 'valorBlue', color: '#61BCBA' },
  { label: 'ピンク', value: 'pink', color: '#F94C97' },
  { label: 'パープル', value: 'purple', color: '#664785' },
  { label: 'Dブラウン', value: 'dBrown', color: '#350816' },
  { label: 'Cブラウン', value: 'brown', color: '#D7CEA5' }
]
export const stitchColorObjs = [
  { label: 'ブラック', value: 'black', color: '#0a0a0a' },
  { label: 'オレンジ', value: 'orange', color: '#e37d00' },
  { label: 'イエロー', value: 'yellow', color: '#debd00' },
  { label: 'コルク', value: 'kurk', color: '#CE7220' },
  { label: 'ピンク', value: 'pink', color: '#ff1493' },
  { label: 'スカイブルー', value: 'skyBlue', color: '#4EB5DC' },
  { label: 'Cブラウン', value: 'cBrown', color: '#baa95b' },
  { label: 'レッド', value: 'red', color: '#c70000' },
  { label: 'ネイビー', value: 'navy', color: '#363C53' },
  { label: 'ブルー', value: 'blue', color: '#002bff' },
  { label: 'ホワイト', value: 'white', color: '#eee' },
  { label: 'グリーン', value: 'green', color: '#009c09' },
  { label: 'パープル', value: 'purple', color: '#6a1ca3' },
  { label: 'グレー', value: 'gray', color: '#828282' }
]
export const WELTINGS = [
  { label: '切りハミ(ホワイト)', value: 'kirihamiWhite', color: '#fff' },
  { label: '玉ハミ(コルク)', value: 'cork', color: '#CE7220' },
  { label: '玉ハミ(オレンジ)', value: 'orange', color: '#FF6C23' },
  { label: '玉ハミ(レッドオレンジ)', value: 'redOrange', color: '#FF362E' },
  { label: '玉ハミ(ブラック)', value: 'black', color: '#1E1E1E' },
  { label: '玉ハミ(Cブラウン)', value: 'camelBrown', color: '#D7CEA5' },
  { label: '玉ハミ(イエロー)', value: 'yellow', color: '#E8C84B' },
  { label: '玉ハミ(レモンイエロー)', value: 'lemonYellow', color: '#D9D943' },
  { label: '玉ハミ(チョコレート)', value: 'chocolate', color: '#6b3b36' },
  { label: '玉ハミ(Dブラウン)', value: 'dBrown', color: '#350816' },
  { label: '玉ハミ(レッド)', value: 'red', color: '#CB1527' },
  { label: '玉ハミ(ブルー)', value: 'blue', color: '#0d42b5' },
  { label: '玉ハミ(ネイビー)', value: 'navy', color: '#0d194a' },
  { label: '玉ハミ(ベイラーブルー)', value: 'valorBlue', color: '#61BCBA' },
  { label: '玉ハミ(マディグラス)', value: 'mardiGras', color: '#4e734c' },
  { label: '玉ハミ(ティール)', value: 'tealGreen', color: '#317290' },
  { label: '玉ハミ(パープル)', value: 'purple', color: '#664785' },
  { label: '玉ハミ(ピンク)', value: 'pink', color: '#F94C97' },
  { label: '玉ハミ(グレー)', value: 'gray', color: '#8A8C8C' },
  { label: '玉ハミ(ホワイト)', value: 'white', color: '#fff' }
]
export const ALL_WELTINGS = [{ label: 'ハミダシなし(ツマミ)', value: 'none', color: '#fff' }, ...WELTINGS]
export const WELTINGS_FOR_CATCHER_MITT = ALL_WELTINGS
export const listLiningsMaterialObjs = [
  { label: 'ムートン(ホワイト)', value: 'moutonWhite', color: '#fff', imageUrl: '/atoms/colorSettings/mouton/white.jpg' },
  { label: 'ムートン(ブラック)', value: 'moutonBlack', color: '#1a1a1a', imageUrl: '/atoms/colorSettings/mouton/black.jpg' }
]
const commonPartsObjs = [
  { label: '全体', value: 'all', order: 0 },
  { label: '捕球面', value: 'catchFace', order: 1 },
  { label: 'ウェブ', value: 'web', order: 2 },
  { label: 'ヘリ革', value: 'edge', order: 4 },
  { label: '親指掛け紐', value: 'thumbHook', order: 5 },
  { label: '小指掛け紐', value: 'littleHook', order: 6 },
  { label: '指カバー/指当て', value: 'indexCover', order: 7 },
  { label: '裏革', value: 'linings', order: 8 }
]
const backBeltStyleGloveNoBeltObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '人差指3', value: 'indexWeb', order: 11 },
  { label: '人差指4', value: 'indexMiddle', order: 12 },
  { label: '中指5', value: 'middleIndex', order: 13 },
  { label: '中指6', value: 'middleRing', order: 14 },
  { label: '薬指7', value: 'ringMiddle', order: 15 },
  { label: '薬指8', value: 'ringLittle', order: 16 },
  { label: '小指9', value: 'littleRing', order: 17 },
  { label: '小指10', value: 'littleOut', order: 18 }
]
const backBeltStyleGloveObjs = [...backBeltStyleGloveNoBeltObjs, { label: 'バンド', value: 'listBelt', order: 19 }]
const backCrownBackGloveObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '人差指3', value: 'indexWeb', order: 11 },
  { label: '人差指4・中指5', value: 'indexLeftMiddleRight', order: 12 },
  { label: '中指6・薬指7', value: 'middleLeftRingRight', order: 13 },
  { label: '薬指8・小指9', value: 'ringLeftLittleRight', order: 14 },
  { label: '小指10', value: 'littleOut', order: 15 },
  { label: 'バンド', value: 'listBelt', order: 16 }
]
const backFirstBackGloveObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2・人差指・中指5', value: 'thumbIndexMiddle', order: 10 },
  { label: '中指6・薬指7', value: 'middleLeftRingRight', order: 11 },
  { label: '薬指8・小指9', value: 'ringLeftLittleRight', order: 12 },
  { label: '小指10', value: 'littleOut', order: 13 },
  { label: 'ベルト', value: 'listBelt', order: 14 }
]
const backGloveUsBackObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2', value: 'thumbWeb', order: 10 },
  { label: '親指根本', value: 'thumbRoot', order: 11 },
  { label: '人差指3', value: 'indexWeb', order: 12 },
  { label: '人差指4', value: 'indexMiddle', order: 13 },
  { label: '中指5', value: 'middleIndex', order: 14 },
  { label: '中指6', value: 'middleRing', order: 15 },
  { label: '薬指7', value: 'ringMiddle', order: 16 },
  { label: '薬指8', value: 'ringLittle', order: 17 },
  { label: '小指9', value: 'littleRing', order: 18 },
  { label: '小指10', value: 'littleOut', order: 19 }
]
const backGloveForUBackObjs = [
  { label: '親指1', value: 'thumbOut', order: 9 },
  { label: '親指2・人差指3', value: 'thumbWebAndIndexWeb', order: 10 },
  { label: '人差指4', value: 'indexMiddle', order: 11 },
  { label: '中指5', value: 'middleIndex', order: 12 },
  { label: '中指6', value: 'middleRing', order: 13 },
  { label: '薬指7', value: 'ringMiddle', order: 14 },
  { label: '薬指8', value: 'ringLittle', order: 15 },
  { label: '小指9', value: 'littleRing', order: 16 },
  { label: '小指10', value: 'littleOut', order: 17 },
  { label: 'バンド', value: 'listBelt', order: 18 }
]
const backOfRegularBackStyleCatcherMittObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '中指6・薬指', value: 'middleLeftRing', order: 10 },
  { label: '指出し', value: 'indexFingerTipOut', order: 11 },
  { label: '小指10', value: 'littleOut', order: 12 },
  { label: '土台', value: 'fingerStand', order: 13 },
  { label: 'バンド', value: 'listBelt', order: 14 }
]
const backOfBeltBackStyleCatcherMittObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '中指6・薬指・小指', value: 'middleLeftRingLittle', order: 10 },
  { label: '土台', value: 'fingerStand' }
]
const backFirstMittObjs = [
  { label: 'バンド', value: 'listBelt', order: 9 },
  { label: '親指', value: 'thumb', order: 10 },
  { label: 'ウェブ下', value: 'underWeb', order: 11 },
  { label: 'ブーメラン', value: 'boomerang', order: 12 }
]
const backFirstMittNoBeltObjs = [
  { label: '親指', value: 'thumb', order: 9 },
  { label: 'ウェブ下', value: 'underWeb', order: 10 },
  { label: 'ブーメラン', value: 'boomerang', order: 11 }
]
export const catcherMittBackPartsObjs = [
  { label: '親指・人差指・中指5', value: 'thumbIndexMiddleRight', order: 12 },
  { label: '中指6・薬指・小指', value: 'middleLeftRingLittle', order: 13 },
  { label: '中指6・薬指', value: 'middleLeftRing', order: 14 },
  { label: '小指10', value: 'littleOut', order: 15 },
  { label: '土台', value: 'fingerStand', order: 16 }
]

// 捕球面
export const catchFacePartsObjs = commonPartsObjs
export const catcherMittCatchFaceObjs = [...commonPartsObjs, { label: 'ターゲット', value: 'catchFaceTarget', order: 9 }]
// 背面
export const gloveBeltBackPartsObjs = [...commonPartsObjs, ...backBeltStyleGloveObjs]
export const gloveBeltBackPartsNoBeltObjs = [...commonPartsObjs, ...backBeltStyleGloveNoBeltObjs]
export const gloveCrownBackPartsObjs = [...commonPartsObjs, ...backCrownBackGloveObjs]
export const gloveFirstBackPartsObjs = [...commonPartsObjs, ...backFirstBackGloveObjs]
export const gloveUsBackPartsObjs = [...commonPartsObjs, ...backGloveUsBackObjs]
export const infielderBackPartsForUBackObjs = [...commonPartsObjs, ...backGloveForUBackObjs]
export const firstMittBackPartsObjs = [...commonPartsObjs, ...backFirstMittObjs]
export const firstMittBackPartsNoBeltObjs = [...commonPartsObjs, ...backFirstMittNoBeltObjs]
export const catcherMittBackofRegularBackStylePartsObjs = [...commonPartsObjs, ...backOfRegularBackStyleCatcherMittObjs]
export const catcherMittBackofBeltBackStylePartsObjs = [...commonPartsObjs, ...backOfBeltBackStyleCatcherMittObjs]
export const partsObjs = [
  ...commonPartsObjs,
  ...catchFacePartsObjs,
  ...backBeltStyleGloveObjs,
  ...backCrownBackGloveObjs,
  ...backFirstBackGloveObjs,
  ...firstMittBackPartsObjs,
  ...catcherMittCatchFaceObjs,
  ...backOfBeltBackStyleCatcherMittObjs,
  ...backOfRegularBackStyleCatcherMittObjs
]

export const otherLabels = [
  { label: '黒xゴールドxゴールド', value: 'ccc', imageUrl: '/atoms/labels/c.jpg' },
  { label: '黒xゴールドxシャンパンゴールド', value: 'ddd', imageUrl: '/atoms/labels/d.jpg' },
  { label: 'ネイビーx赤xゴールド', value: 'eee', imageUrl: '/atoms/labels/e.jpg' },
  { label: '赤xネイビーxゴールド', value: 'fff', imageUrl: '/atoms/labels/f.jpg' },
  { label: '黒xゴールドxシルバー', value: 'ggg', imageUrl: '/atoms/labels/g.jpg' },
  { label: 'ブルーx赤xゴールド', value: 'hhh', imageUrl: '/atoms/labels/h.jpg' },
  { label: '黒xゴールドxブルー', value: 'iii', imageUrl: '/atoms/labels/i.jpg' },
  { label: '黒xプラチナゴールドxゴールド', value: 'jjj', imageUrl: '/atoms/labels/j.jpg' },
  { label: '黒x黒x黒', value: 'kkk', imageUrl: '/atoms/labels/k.jpg' },
  { label: '黒xゴールドxピンク', value: 'uuu', imageUrl: '/atoms/labels/u.jpg' },
  { label: '黒xライトゴールドxライトゴールド', value: 'yyy', imageUrl: '/atoms/labels/y.jpg' }
]

export const atomsLabelObjs = [
  { label: '黒xゴールドxゴールド', value: 'ccc', imageUrl: '/atoms/labels/c.jpg' },
  { label: '黒xゴールドxシャンパンゴールド', value: 'ddd', imageUrl: '/atoms/labels/d.jpg' },
  { label: 'ネイビーx赤xゴールド', value: 'eee', imageUrl: '/atoms/labels/e.jpg' },
  { label: '赤xネイビーxゴールド', value: 'fff', imageUrl: '/atoms/labels/f.jpg' },
  { label: '黒xゴールドxシルバー', value: 'ggg', imageUrl: '/atoms/labels/g.jpg' },
  { label: 'ブルーx赤xゴールド', value: 'hhh', imageUrl: '/atoms/labels/h.jpg' },
  { label: '黒xゴールドxブルー', value: 'iii', imageUrl: '/atoms/labels/i.jpg' },
  { label: '黒xプラチナゴールドxゴールド', value: 'jjj', imageUrl: '/atoms/labels/j.jpg' },
  { label: '黒x黒x黒', value: 'kkk', imageUrl: '/atoms/labels/k.jpg' },
  { label: '黒xゴールドxピンク', value: 'uuu', imageUrl: '/atoms/labels/u.jpg' },
  { label: '黒xライトゴールドxライトゴールド', value: 'yyy', imageUrl: '/atoms/labels/y.jpg' },
  { label: '黒xシリコンゴールド', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '黒xシリコンプラチナ', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '黒xシリコンブラックラメ', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '黒xシリコンブルー', value: 'ooo', imageUrl: '/atoms/labels/o.jpg' },
  { label: '黒xシリコンパープル', value: 'ppp', imageUrl: '/atoms/labels/p.jpg' },
  { label: '黒xシリコンレッド', value: 'qqq', imageUrl: '/atoms/labels/q.jpg' },
  { label: '黒xシリコングリーン', value: 'rrr', imageUrl: '/atoms/labels/r.jpg' },
  { label: '黒xシリコンシルバー', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const atomsERMLabelObjs = [
  { label: '黒xシリコンゴールド', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '黒xシリコンプラチナ', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '黒xシリコンブラックラメ', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '黒xシリコンブルー', value: 'ooo', imageUrl: '/atoms/labels/o.jpg' },
  { label: '黒xシリコンパープル', value: 'ppp', imageUrl: '/atoms/labels/p.jpg' },
  { label: '黒xシリコンレッド', value: 'qqq', imageUrl: '/atoms/labels/q.jpg' },
  { label: '黒xシリコングリーン', value: 'rrr', imageUrl: '/atoms/labels/r.jpg' },
  { label: '黒xシリコンシルバー', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const moutonObjs = [
  { label: '白', value: 'white', color: '#fff' },
  { label: '黒', value: 'black', color: '#1E1E1E' }
]

// 硬式
export const dispatchEuropeanSteerJa = {
  linings: EUROPEAN_STEER_LEATHER_COLORS_JA,
  all: EUROPEAN_STEER_LEATHER_COLORS_JA,
  catchFace: EUROPEAN_STEER_LEATHER_COLORS_JA,
  web: EUROPEAN_STEER_LEATHER_COLORS_JA,
  web2: EUROPEAN_STEER_LEATHER_COLORS_JA,
  edge: BINDING_COLORS_JA,
  thumbHook: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbWebAndIndexWeb: EUROPEAN_STEER_LEATHER_COLORS_JA,
  littleHook: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexCover: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbOut: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbWeb: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbRoot: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbIndexMiddle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumbIndexMiddleRight: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexWeb: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexMiddle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  middleIndex: EUROPEAN_STEER_LEATHER_COLORS_JA,
  middleRing: EUROPEAN_STEER_LEATHER_COLORS_JA,
  middleLeftRing: EUROPEAN_STEER_LEATHER_COLORS_JA,
  middleLeftRingRight: EUROPEAN_STEER_LEATHER_COLORS_JA,
  middleLeftRingLittle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  ringMiddle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  ringLittle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  ringLeftLittleRight: EUROPEAN_STEER_LEATHER_COLORS_JA,
  littleRing: EUROPEAN_STEER_LEATHER_COLORS_JA,
  littleOut: EUROPEAN_STEER_LEATHER_COLORS_JA,
  listBelt: EUROPEAN_STEER_LEATHER_COLORS_JA,
  thumb: EUROPEAN_STEER_LEATHER_COLORS_JA,
  boomerang: EUROPEAN_STEER_LEATHER_COLORS_JA,
  underWeb: EUROPEAN_STEER_LEATHER_COLORS_JA,
  catchFaceTarget: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexLeftMiddleRight: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexAndMiddle: EUROPEAN_STEER_LEATHER_COLORS_JA,
  fingerStand: EUROPEAN_STEER_LEATHER_COLORS_JA,
  indexFingerTipOut: EUROPEAN_STEER_LEATHER_COLORS_JA
}

// 硬式・軟式
export const dispatchNorthSteerJa = {
  linings: NORTH_LEATHER_COLORS_JA,
  all: NORTH_LEATHER_COLORS_JA,
  catchFace: NORTH_LEATHER_COLORS_JA,
  web: NORTH_LEATHER_COLORS_JA,
  web2: NORTH_LEATHER_COLORS_JA,
  edge: BINDING_COLORS_JA,
  thumbHook: NORTH_LEATHER_COLORS_JA,
  thumbWebAndIndexWeb: NORTH_LEATHER_COLORS_JA,
  littleHook: NORTH_LEATHER_COLORS_JA,
  indexCover: NORTH_LEATHER_COLORS_JA,
  thumbOut: NORTH_LEATHER_COLORS_JA,
  thumbWeb: NORTH_LEATHER_COLORS_JA,
  thumbRoot: NORTH_LEATHER_COLORS_JA,
  thumbIndexMiddle: NORTH_LEATHER_COLORS_JA,
  thumbIndexMiddleRight: NORTH_LEATHER_COLORS_JA,
  indexWeb: NORTH_LEATHER_COLORS_JA,
  indexMiddle: NORTH_LEATHER_COLORS_JA,
  middleIndex: NORTH_LEATHER_COLORS_JA,
  middleRing: NORTH_LEATHER_COLORS_JA,
  middleLeftRing: NORTH_LEATHER_COLORS_JA,
  middleLeftRingRight: NORTH_LEATHER_COLORS_JA,
  middleLeftRingLittle: NORTH_LEATHER_COLORS_JA,
  ringMiddle: NORTH_LEATHER_COLORS_JA,
  ringLittle: NORTH_LEATHER_COLORS_JA,
  ringLeftLittleRight: NORTH_LEATHER_COLORS_JA,
  littleRing: NORTH_LEATHER_COLORS_JA,
  littleOut: NORTH_LEATHER_COLORS_JA,
  listBelt: NORTH_LEATHER_COLORS_JA,
  thumb: NORTH_LEATHER_COLORS_JA,
  boomerang: NORTH_LEATHER_COLORS_JA,
  underWeb: NORTH_LEATHER_COLORS_JA,
  catchFaceTarget: NORTH_LEATHER_COLORS_JA,
  indexLeftMiddleRight: NORTH_LEATHER_COLORS_JA,
  indexAndMiddle: NORTH_LEATHER_COLORS_JA,
  fingerStand: NORTH_LEATHER_COLORS_JA,
  indexFingerTipOut: NORTH_LEATHER_COLORS_JA
}
// 硬式・軟式
export const dispatchSteerJa = {
  linings: STEER_LEATHER_COLORS_JA,
  all: STEER_LEATHER_COLORS_JA,
  catchFace: STEER_LEATHER_COLORS_JA,
  web: STEER_LEATHER_COLORS_JA,
  web2: STEER_LEATHER_COLORS_JA,
  edge: BINDING_COLORS_JA,
  thumbHook: STEER_LEATHER_COLORS_JA,
  thumbWebAndIndexWeb: STEER_LEATHER_COLORS_JA,
  littleHook: STEER_LEATHER_COLORS_JA,
  indexCover: STEER_LEATHER_COLORS_JA,
  thumbOut: STEER_LEATHER_COLORS_JA,
  thumbWeb: STEER_LEATHER_COLORS_JA,
  thumbRoot: STEER_LEATHER_COLORS_JA,
  thumbIndexMiddle: STEER_LEATHER_COLORS_JA,
  thumbIndexMiddleRight: STEER_LEATHER_COLORS_JA,
  indexWeb: STEER_LEATHER_COLORS_JA,
  indexMiddle: STEER_LEATHER_COLORS_JA,
  middleIndex: STEER_LEATHER_COLORS_JA,
  middleRing: STEER_LEATHER_COLORS_JA,
  middleLeftRing: STEER_LEATHER_COLORS_JA,
  middleLeftRingRight: STEER_LEATHER_COLORS_JA,
  middleLeftRingLittle: STEER_LEATHER_COLORS_JA,
  ringMiddle: STEER_LEATHER_COLORS_JA,
  ringLittle: STEER_LEATHER_COLORS_JA,
  ringLeftLittleRight: STEER_LEATHER_COLORS_JA,
  littleRing: STEER_LEATHER_COLORS_JA,
  littleOut: STEER_LEATHER_COLORS_JA,
  listBelt: STEER_LEATHER_COLORS_JA,
  thumb: STEER_LEATHER_COLORS_JA,
  boomerang: STEER_LEATHER_COLORS_JA,
  underWeb: STEER_LEATHER_COLORS_JA,
  catchFaceTarget: STEER_LEATHER_COLORS_JA,
  indexLeftMiddleRight: STEER_LEATHER_COLORS_JA,
  indexAndMiddle: STEER_LEATHER_COLORS_JA,
  fingerStand: STEER_LEATHER_COLORS_JA,
  indexFingerTipOut: STEER_LEATHER_COLORS_JA
}

// USモデルで使用しているはず👇
export const dispatchObjects = {
  linings: leatherColorObjsJa,
  listLiningsMaterial: listLiningsMaterialObjs,
  all: leatherColorObjsJa,
  catchFace: leatherColorObjsJa,
  web: leatherColorObjsJa,
  web2: leatherColorObjsJa,
  edge: BINDING_COLORS_JA,
  thumbHook: leatherColorObjsJa,
  thumbWebAndIndexWeb: leatherColorObjsJa,
  littleHook: leatherColorObjsJa,
  indexCover: leatherColorObjsJa,
  thumbOut: leatherColorObjsJa,
  thumbWeb: leatherColorObjsJa,
  thumbRoot: leatherColorObjsJa,
  thumbIndexMiddle: leatherColorObjsJa,
  thumbIndexMiddleRight: leatherColorObjsJa,
  indexWeb: leatherColorObjsJa,
  indexMiddle: leatherColorObjsJa,
  middleIndex: leatherColorObjsJa,
  middleRing: leatherColorObjsJa,
  middleLeftRing: leatherColorObjsJa,
  middleLeftRingRight: leatherColorObjsJa,
  middleLeftRingLittle: leatherColorObjsJa,
  ringMiddle: leatherColorObjsJa,
  ringLittle: leatherColorObjsJa,
  ringLeftLittleRight: leatherColorObjsJa,
  littleRing: leatherColorObjsJa,
  littleOut: leatherColorObjsJa,
  listBelt: leatherColorObjsJa,
  thumb: leatherColorObjsJa,
  boomerang: leatherColorObjsJa,
  underWeb: leatherColorObjsJa,
  catchFaceTarget: leatherColorObjsJa,
  indexLeftMiddleRight: leatherColorObjsJa,
  indexAndMiddle: leatherColorObjsJa,
  fingerStand: leatherColorObjsJa,
  indexFingerTipOut: leatherColorObjsJa
}

export const PARTS_BUTTON_OPTION = 'atoms-parts-button-option'
export const LEATHER_COLOR_BUTTON_OPTION = 'atoms-leather-color-button-option'
export const LACE_COLOR_BUTTON_OPTION = 'atoms-lace-color-button-option'
export const EDGE_COLOR_BUTTON_OPTION = 'atoms-edge-color-button-option'
export const STITCH_COLOR_BUTTON_OPTION = 'atoms-stitch-color-button-option'
export const HAMIDASHI_COLOR_BUTTON_OPTION = 'atoms-hamidashi-color-button-option'
export const MOUTON_BUTTON_OPTION = 'atoms-mouton-button-option'
export const ATOMS_LABEL_BUTTON_OPTION = 'atoms-label-button-option'
