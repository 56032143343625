export const dominantArmEnObjs = [
  { label: 'Right', value: 'rightThrow' },
  { label: 'Left', value: 'leftThrow' }
]

export const sizeEnObjs = [
  { label: '2.54 inch larger', value: 'large10', price: 3300 },
  { label: '1.27 inch larger', value: 'large5', price: 3300 },
  { label: 'No change', value: 'default', price: 0 },
  { label: '1.27 inch smaller', value: 'small5', price: 3300 },
  { label: '2.54 inch smaller', value: 'small10', price: 3300 }
]

export const backLaceEnObjs = [
  { label: 'Straight', value: 'straight', imageUrl: '/atoms/baseSettings/backLace/exist.jpg' },
  { label: 'None', value: 'none', imageUrl: '/atoms/baseSettings/backLace/none.jpg' }
]
export const backStyleEnObjs = [
  { label: 'Belt back', value: 'beltBackStyle' },
  { label: 'First back', value: 'firstBackStyle' },
  { label: 'Wing Tip back', value: 'crownBackStyle' },
  { label: 'Regular Back', value: 'regularBackStyle' }
]
export const backStyleForPitcherEnObjs = [
  { label: 'Belt back', value: 'beltBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/regular.jpg' },
  { label: 'First back', value: 'firstBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/firstBack.jpg' },
  { label: 'Wing Tip back', value: 'crownBackStyle', imageUrl: '/atoms/baseSettings/backStyle/glove/crownBack.jpg' }
]
export const backStyleForCatcherMittEnObjs = [
  { label: 'Belt back', value: 'beltBackStyle', imageUrl: '' },
  { label: 'Regular Back', value: 'regularBackStyle', imageUrl: '' }
]

export const fingerGuardTypeAllEnObjects = [
  { label: 'Cover (index)', value: 'indexCover', imageUrl: '/atoms/baseSettings/fingerGuardType/indexCover.jpg' },
  { label: 'Cover (middle)', value: 'middleCover', imageUrl: '/atoms/baseSettings/fingerGuardType/middleCover.jpg' },
  { label: 'Pad (index)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: 'Pad (middle)', value: 'middlePad', imageUrl: '/atoms/baseSettings/fingerGuardType/middlePad.jpg' },
  { label: 'None', value: 'none', imageUrl: '/atoms/baseSettings/fingerGuardType/none.jpg' }
]
export const fingerGuardTypeEnObjs = [
  { label: 'Cover (index)', value: 'indexCover', imageUrl: '/atoms/baseSettings/fingerGuardType/indexCover.jpg' },
  { label: 'Pad (index)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: 'None', value: 'none', imageUrl: '' }
]

export const fingerGuardForFirstMittEnObjs = [
  { label: 'Pad (index)', value: 'indexPad', imageUrl: '/atoms/baseSettings/fingerGuardType/indexPad.jpg' },
  { label: 'None', value: 'none', imageUrl: '/atoms/baseSettings/fingerGuardType/none.jpg' }
]

export const fingerGuardTypeForOutfielderEnObjs = fingerGuardTypeAllEnObjects

export const materialPackEnObjs = [
  { label: 'Vertical heal style', value: 'vertical', imageUrl: '/atoms/baseSettings/materialPack/vertical.jpg' },
  { label: 'W horizontal heal style', value: 'sideDouble', imageUrl: '/atoms/baseSettings/materialPack/sideDouble.jpg' }
]

export const moldingEnObjs = [
  { label: 'Molding process', value: 'moldingProcess', price: 8800 }, //$36
  { label: 'None', value: 'none', price: 0 }
]

export const loopOfRingFingerEnObjs = [
  { label: 'With loop of ring finger', value: 'loopOfRingFinger', price: 3300, imageUrl: '/atoms/baseSettings/loopOfFinger/exist.jpg' },
  { label: 'None', value: 'none', price: 0, imageUrl: '/atoms/baseSettings/loopOfFinger/none.jpg' }
]

export const en2JaBasePartsTranslates = [
  { en: 'Right', japanese: '右投げ' },
  { en: 'Left', japanese: '左投げ' },
  { en: '2.54 inch larger', japanese: '10mm大きく' },
  { en: '1.27 inch larger', japanese: '5mm大きく' },
  { en: 'No change', japanese: '基本モデルズバリ' },
  { en: '1.27 inch smaller', japanese: '5mm小さく' },
  { en: '2.54 inch smaller', japanese: '10mm小さく' },
  { en: 'Straight', japanese: '有り' },
  { en: 'None', japanese: '無し' },
  { en: 'Belt back', japanese: 'ベルト' },
  { en: 'First back', japanese: 'ファーストバック' },
  { en: 'Wing Tip back', japanese: 'ウィングチップ' },
  { en: 'Regular Back', japanese: 'レギュラー' },
  { en: 'Cover (index)', japanese: '指カバー有り' },
  { en: 'Pad (index)', japanese: '指アテ有り(人差指)' },
  { en: 'Pad (middle)', japanese: '指アテ有り(中指)' },
  { en: 'Vertical heal style', japanese: 'たてとじ' },
  { en: 'W horizontal heal style', japanese: 'よことじ' },
  { en: 'Molding process', japanese: '型付け有り' }
]
