import { State, Action } from 'src/types'
import {
  SET_SELECTED_PARTS,
  SET_ALL_LEATHER_COLOR,
  SET_BASE_MODEL,
  SET_DOMINANT_ARM,
  SET_SIZE,
  SET_BACK_LACE,
  SET_MOUTON_COLOR,
  SET_WEB_PARTS,
  SET_ATOMS_LABEL,
  SET_LEATHER_COLOR,
  SET_EDGE_COLOR,
  SET_LININGS,
  SET_HAMIDASHI,
  SET_FINGER_GUARD_TYPE,
  SET_LACE_COLOR,
  SET_MATERIAL_PACK,
  SET_STITCH_COLOR,
  SET_BACK_STYLE,
  SET_EMBROIDERIES,
  ADD_EMBROIDERY,
  REMOVE_EMBROIDERY,
  SET_WEB_COLOR,
  SET_THUMB_HOOK,
  SET_LITTLE_HOOK,
  SET_INDEX_COVER_COLOR,
  SET_PERSONAL,
  SET_THUMB_OUT_COLOR,
  SET_THUMB_WEB_COLOR,
  SET_INDEX_WEB_COLOR,
  SET_INDEX_MIDDLE_COLOR,
  SET_MIDDLE_INDEX_COLOR,
  SET_MIDDLE_RING_COLOR,
  SET_RING_MIDDLE_COLOR,
  SET_RING_LITTLE_COLOR,
  SET_LITTLE_RING_COLOR,
  SET_LITTLE_OUT_COLOR,
  SET_LIST_BELT_COLOR,
  SET_THUMB_INDEX_MIDDLE,
  SET_THUMB_WEB_AND_INDEX_WEB,
  SET_MIDDLE_LEFT_RING_RIGHT_COLOR,
  SET_RING_LEFT_LITTLE_RIGHT_COLOR,
  SET_INDEX_LEFT_MIDDLE_RIGHT_COLOR,
  SET_TANNER,
  SET_THUMB_ROOT_COLOR,
  SET_MOLDIGN_ORDER,
  SET_LOOP_OF_RING_FINGER,
  SET_FIGURE_PANEL_NUMBER
} from 'src/features/brands/atoms/japan/constants/actionType'
import { leatherColorObjsJa } from 'src/features/brands/atoms/japan/constants/colors'
import {
  colorInitialState,
  gloveColorInitialState,
  gloveColorInitialStateEn,
  gloveColorInitialStateKo,
  initialEmbroideryState
} from 'src/features/brands/atoms/japan/reducer'
import {
  baseInitialState,
  baseInitialStateEn,
  baseInitialStateKo,
  colorInitialStateEn,
  colorInitialStateKo,
  initialPersonalState,
  unselectedColorState,
  unselectedColorStateEn,
  unselectedColorStateKo,
  unselectedState,
  unselectedStateEn,
  unselectedStateKo
} from 'src/features/brands/reducer'

export const initialPitcherState: State = {
  ...baseInitialState,
  tanner: unselectedState,
  kinds: unselectedState,
  backLace: unselectedState,
  backStyle: unselectedState,
  materialPack: unselectedState,
  webParts: unselectedState,
  loopOfRingFinger: unselectedState,
  loopOfRingFingerColor: unselectedColorState,
  ...colorInitialState,
  ...gloveColorInitialState,
  mouton: unselectedState,
  atomsLabel: unselectedState,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}
export const initialPitcherStateEn: State = {
  ...baseInitialStateEn,
  tanner: unselectedStateEn,
  backLace: unselectedStateEn,
  backStyle: unselectedStateEn,
  materialPack: unselectedStateEn,
  webParts: unselectedStateEn,
  moldingOrder: unselectedStateEn,
  loopOfRingFinger: unselectedStateEn,
  loopOfRingFingerColor: unselectedColorStateEn,
  ...colorInitialStateEn,
  ...gloveColorInitialStateEn,
  mouton: unselectedStateEn,
  atomsLabel: unselectedStateEn,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}
export const initialPitcherStateKo: State = {
  ...baseInitialStateKo,
  ...colorInitialStateKo,
  ...gloveColorInitialStateKo,
  tanner: unselectedStateKo,
  loopOfRingFinger: unselectedStateKo,
  loopOfRingFingerColor: unselectedColorStateKo,
  backLace: unselectedStateKo,
  backStyle: unselectedStateKo,
  materialPack: unselectedStateKo,
  webParts: unselectedStateKo,
  mouton: unselectedColorStateKo,
  atomsLabel: unselectedStateKo,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}

export const pitcherReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TANNER:
      return { ...state, tanner: action.tanner }
    case SET_SELECTED_PARTS:
      return { ...state, parts: action.parts }
    case SET_ALL_LEATHER_COLOR:
      return {
        ...state,
        all: action.all,
        catchFace: action.all,
        web: action.all,
        web2: action.all,
        thumbHook: action.all,
        littleHook: action.all,
        edge: action.all,
        indexCover: action.all,
        // indexAndThumb: action.all,
        listBelt: action.all,
        // 裏革にはない色が「全体」として指定されたら、裏革のみ既存stateを維持させる
        // 裏革にもある色が「全体」として指定されたらもちろん反映を許可
        linings: leatherColorObjsJa.some((x) => x.color === action.all.color) ? action.all : state.linings, // 裏革
        // 投手手用グラブ
        thumbOut: action.all,
        thumbWeb: action.all,
        thumbRoot: action.all,
        thumbWebAndIndexWeb: action.all,
        thumbIndexMiddle: action.all,
        thumbIndexMiddleRight: action.all,
        indexWeb: action.all,
        indexMiddle: action.all,
        indexLeftMiddleRight: action.all,
        middleIndex: action.all,
        middleRing: action.all,
        middleLeftRingRight: action.all,
        ringMiddle: action.all,
        ringLittle: action.all,
        ringLeftLittleRight: action.all,
        littleRing: action.all,
        littleOut: action.all,
        loopOfRingFingerColor: action.all
      }
    case SET_BASE_MODEL:
      const isSequentialRollGlove = ['AGL-NE001(AKG-1)', 'AGL-NE011(AKG-21)'].includes(action.baseModel.productNumber)
      const isAGL_NE105 = action.baseModel.productNumber === 'AGL-NE105'
      return {
        ...state,
        baseModel: action.baseModel,
        kinds: action.kinds,
        bankLaceDirection: isSequentialRollGlove ? { label: 'レギュラー巻き', value: 'sequentialRoll' } : { label: '逆巻き', value: 'reverseRoll' },
        backStyle: isAGL_NE105 ? { label: 'ベルト', value: 'beltBackStyle' } : state.backStyle
      }
    case SET_DOMINANT_ARM:
      return { ...state, dominantArm: action.dominantArm }
    case SET_BACK_STYLE:
      return { ...state, backStyle: action.backStyle, figurePanelNum: 1 }
    case SET_FINGER_GUARD_TYPE:
      return { ...state, fingerGuardType: action.fingerGuardType, figurePanelNum: 1 }
    case SET_MATERIAL_PACK:
      return { ...state, materialPack: action.materialPack, figurePanelNum: 0 }
    case SET_SIZE:
      return { ...state, size: action.size }
    case SET_BACK_LACE:
      return { ...state, backLace: action.backLace, figurePanelNum: 1 }
    case SET_WEB_PARTS:
      return { ...state, webParts: action.webParts }
    case SET_ATOMS_LABEL:
      return { ...state, atomsLabel: action.atomsLabel, figurePanelNum: 1 }
    case SET_MOLDIGN_ORDER:
      return { ...state, moldingOrder: action.moldingOrder }
    case SET_LOOP_OF_RING_FINGER:
      return { ...state, loopOfRingFinger: action.loopOfRingFinger, figurePanelNum: 1 }
    case SET_LEATHER_COLOR:
      return { ...state, catchFace: action.catchFace }
    case SET_EDGE_COLOR:
      return { ...state, edge: action.edge }
    case SET_WEB_COLOR:
      return { ...state, web: action.web, web2: action.web }
    case SET_THUMB_OUT_COLOR:
      return { ...state, thumbOut: action.thumbOut }
    case SET_THUMB_WEB_COLOR:
      return { ...state, thumbWeb: action.thumbWeb }
    case SET_THUMB_ROOT_COLOR:
      return { ...state, thumbRoot: action.thumbRoot }
    case SET_THUMB_INDEX_MIDDLE: // ファーストバックのみ
      return { ...state, thumbIndexMiddle: action.thumbIndexMiddle }
    case SET_THUMB_WEB_AND_INDEX_WEB:
      return { ...state, thumbWebAndIndexWeb: action.thumbWebAndIndexWeb }
    case SET_INDEX_WEB_COLOR:
      return { ...state, indexWeb: action.indexWeb }
    case SET_INDEX_MIDDLE_COLOR:
      return { ...state, indexMiddle: action.indexMiddle }
    case SET_INDEX_LEFT_MIDDLE_RIGHT_COLOR:
      return { ...state, indexLeftMiddleRight: action.indexLeftMiddleRight }
    case SET_MIDDLE_INDEX_COLOR:
      return { ...state, middleIndex: action.middleIndex }
    case SET_MIDDLE_RING_COLOR:
      return { ...state, middleRing: action.middleRing }
    case SET_MIDDLE_LEFT_RING_RIGHT_COLOR:
      return { ...state, middleLeftRingRight: action.middleLeftRingRight }
    case SET_RING_LEFT_LITTLE_RIGHT_COLOR:
      return { ...state, ringLeftLittleRight: action.ringLeftLittleRight }
    case SET_RING_MIDDLE_COLOR:
      return { ...state, ringMiddle: action.ringMiddle }
    case SET_RING_LITTLE_COLOR:
      return { ...state, ringLittle: action.ringLittle }
    case SET_LITTLE_RING_COLOR:
      return { ...state, littleRing: action.littleRing }
    case SET_LITTLE_OUT_COLOR:
      return { ...state, littleOut: action.littleOut }
    case SET_LIST_BELT_COLOR:
      return { ...state, listBelt: action.listBelt }
    case SET_LININGS:
      return { ...state, linings: action.linings, loopOfRingFingerColor: action.linings, figurePanelNum: 1 }
    case SET_HAMIDASHI:
      return { ...state, hamidashi: action.hamidashi }
    case SET_LACE_COLOR:
      return { ...state, lace: action.lace }
    case SET_STITCH_COLOR:
      return { ...state, stitch: action.stitch }
    case SET_MOUTON_COLOR:
      return { ...state, mouton: action.mouton }
    case SET_EMBROIDERIES:
      return { ...state, embroideries: action.embroideries }
    case ADD_EMBROIDERY: // incrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case REMOVE_EMBROIDERY: // decrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case SET_THUMB_HOOK:
      return { ...state, thumbHook: action.thumbHook }
    case SET_LITTLE_HOOK:
      return { ...state, littleHook: action.littleHook }
    case SET_INDEX_COVER_COLOR:
      return { ...state, indexCover: action.indexCover }
    case SET_PERSONAL:
      return { ...state, personal: action.personal }
    case SET_FIGURE_PANEL_NUMBER:
      return { ...state, figurePanelNum: action.figurePanelNum }
    default:
      return state
  }
}
