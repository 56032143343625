import {
  atomsERMLabelEnObjs,
  atomsLabelEnObjs,
  catcherMittBackofBeltBackStylePartsEnObjs,
  catcherMittBackofRegularBackStylePartsEnObjs,
  catcherMittBackPartsEnObjs,
  catcherMittCatchFaceEnObjs,
  catchFacePartsEnObjs,
  dispatchEnObjects,
  dispatchEuropeanSteerEn,
  firstMittBackPartsEnObjs,
  firstMittBackPartsNoBeltEnObjs,
  gloveBeltBackPartsEnObjs,
  gloveBeltBackPartsNoBeltEnObjs,
  gloveCrownBackPartsEnObjs,
  gloveFirstBackPartsEnObjs,
  gloveUsBackPartsEnObjs,
  hamidashiAllEnObjs,
  hamidashiEnObjs,
  hamidashiForCatcherEnObjs,
  laceColorEnObjs,
  leatherColorEnObjesByTanner,
  leatherColorObjsEn,
  listLiningsMaterialEnObjs,
  stitchColorEnObjs,
  tannerEnObjs,
  tannerObjs,
  uBackEnObjs
} from 'src/features/brands/atoms/us/constants/colors'
import {
  backLaceEnObjs,
  backStyleEnObjs,
  backStyleForCatcherMittEnObjs,
  backStyleForPitcherEnObjs,
  dominantArmEnObjs,
  fingerGuardForFirstMittEnObjs,
  fingerGuardTypeAllEnObjects,
  fingerGuardTypeEnObjs,
  fingerGuardTypeForOutfielderEnObjs,
  loopOfRingFingerEnObjs,
  materialPackEnObjs,
  moldingEnObjs,
  sizeEnObjs
} from 'src/features/brands/atoms/us/constants/bases'
import { colorEnObjs, positionEnObjs, shadowEdgeColorEnObjs, typeFaceEnAllObjs } from 'src/features/brands/atoms/us/constants/embroidery'
import { initialStateCatcherMittEn } from 'src/features/brands/atoms/japan/reducer/catcher'
import { initialStateFirstMittEn } from 'src/features/brands/atoms/japan/reducer/first'
import {
  initialInfielderStateEn,
  initialOutfielderStateEn,
  initialPitcherStateEn,
  initialUsGloveStateEn
} from 'src/features/brands/atoms/us/reducer/glove'
import { leagueEnOptions, positionEnOptions } from 'src/locales/constants'
import { initialYouthState } from 'src/features/brands/atoms/japan/reducer/youth'

export default {
  SIMULATABLE_BRANDS: 'Simulatable brands',
  ORDER_SIMULATION: 'Order Simulation',
  IF_START_SIMULATION: ``,
  IF_START_SIMULATION_2: ``,
  START_BUTTON: 'Start Simulation With Agreement',
  CHECK_SIMULATION: 'Check Simulation',
  TERMS_OF_USE: 'Terms of Use',
  TERMS_OF_USE_ITEM_1: 'This system is designed to allow the ATOMS Group to schlemish order grabs.',
  TERMS_OF_USE_ITEM_2: 'Some designs and colors may differ from the actual product.',
  TERMS_OF_USE_ITEM_3: 'Please check with your local league for restrictions on use.',
  TERMS_OF_USE_ITEM_4: 'We are not responsible for any unauthorized use of the Grab ID issued at the time of simulation.',
  TERMS_OF_USE_ITEM_5: 'We will make every effort to protect the privacy of information by using encryption and other methods.',
  TERMS_OF_USE_ITEM_6:
    'Use of this system is at your own risk. We assume no responsibility for any damages resulting from the use of information obtained from this system and other websites that are not linked to this system.',
  TERMS_OF_USE_ITEM_7: 'The Companys system is subject to review and revision from time to time.',
  TRANSLATE_CAUTION_DESCRIBE: '',
  FOR_DETAIL: 'for detail...',
  HOW_TO_OFF_GOOGLE_AUTO_TRANSLATE: '',
  DISABLE_MESSAGE_EVER: '',
  CLOSE: 'Close',
  PRODUCT_PAGE: 'Product page',
  HARD_GLOVE_MITT: 'Hardball Gloves & Mitts',
  HARD: 'Hardball',
  SOFT_BALL_GLOVE_MITT: 'Japanese Softball Gloves & Mitts',
  SOFT: 'Japanese Softball',
  SPIKES: 'Spikes',
  BAT: 'Bat',
  GLOVE: 'Grove',
  MITT: 'Mitt',
  COMMING_SOON: '(coming soon...)',
  BRAND_SELECTION_PAGE: 'Brand Selection Page',
  POSITION_SELECTION_PAGE: 'Position Selection Page',
  SERIES_SELECTION_PAGE: 'Series Selection Page',
  PITCHER: 'Pitcher',
  CATCHER: 'Catcher',
  INFIELDER: 'Infielder',
  OUTFIELDER: 'Outfielder',
  FIRST_BASEMAN: 'First baseman',
  JAPAN_SERIES: 'Japan Series',
  USA_SERIES: 'USA Series',
  KOREA_SERIES: 'Korea series',
  PLEASE_SELECT_MODEL: 'Please select a base model',
  PRODUCT_NUMBER: '',
  START_SIMULATION: 'Start Simulation',
  // ATOMS_投手用
  AGL_NE001: { description: 'For orthodox pitchers. Deep pockets and side hanging model.', size: 11.9 },
  AGL_NE011: { description: 'Small pitcher, lightweight model', size: 11.7 },
  AGL_NE102: { description: 'model that can be used both shallow and deep.', size: 11.14 },
  AGL_NE105: { description: 'All-round model with deep pockets for a secure catch.', size: 11.42 },
  AGL_NE501: { description: 'For smaller infielders. Smaller hand opening, suitable for youth.', size: 10.94 },
  AGL_NE801: { description: `Small outfielder's glove. Lightweight model with deep pockets.`, size: 12.01 },
  AGL_NE008: { description: 'Model with deep pockets for firm capture.', size: 12.99 },
  AGL_NE002: { description: `Catcher's mitt with regular back.`, size: 12.8 },
  AGL_NE003: { description: 'First mitt with a wide pocket for a firm catch.', size: 12.8 },
  APL_NE021: { description: 'Versatile model for infielders that can be deep or shallow depending on the molding.', size: 11.3 },
  APL_NE026: { description: 'tly smaller but deep, for players who catch under the web.', size: 11.14 },
  APL_NE066: {
    description: 'The glove opens easily for players who prefer to catch the ball in the center.',
    size: 11.42
  },
  APL_NE102: { description: 'This model can be used for pitcher and infielder.', size: 11.14 },
  APL_NE702: { description: 'Vertical and deep for outfielders. This model is easy to operate up to the fingertips.', size: 12.6 },

  AKG_1: { description: 'For orthodox pitchers. deep pocket and horizontal model.', size: 11.8 },
  AKG_11: { description: 'Small model for pitchers. Vertical and excellent style for fielding.', size: 11.4 },
  AKG_21: { description: 'First vertical and vertical-bound glove for ATOMS pitchers', size: 29.8 },
  AKG_PRO46: { description: 'NPB, Orix Buffaloes professional pitcher model.', size: 29.0 },
  ATR_001: { description: 'Deep pocket and vertical model. For firm grasp pitchers.', size: 11.4 },
  US_PITCHER: { description: 'Deep pocket type. Deep and vertical, for pitchers who can grip it firmly.', size: 12.0 },
  // ATOMS_内野手用
  AKG_JS4_PLUS: { description: 'A model that can respond to any type of strike in a transformable manner.', size: 11.29 },
  AKG_JS5: { description: 'The bank is narrow and designed for players who grab and catch firmly.', size: 11.14 },
  AKG_16: { description: 'ATOMS lightest weight design. Sharp fingers and excellent maneuverability make it easy to handle the goro.', size: 10.94 },
  AKG_4: { description: 'A model that can respond to any type of strike in a transformable manner.', size: 28.0 },
  AKG_5: { description: `Super standard of modern baseball style. A model that easily adapts to the player's style of play.`, size: 11.14 },
  AKG_6: { description: 'This model reduces the width of the hand opening.', size: 29.6 },
  APL_UR2: { description: 'A model for two players that grips firmly.', size: 29.0 },
  APL_UR3: { description: 'Shallowest model.', size: 27.5 },
  APL_UR4: { description: 'Designed to prevent deepening. For players who want to use a wider palm.', size: 11.0 },
  APL_UR5: { description: 'Designed to withstand strong hits.', size: 28.3 },
  APL_UR10: { description: 'U-back model with edged web.', size: 28.0 },
  APL_UR026_PLUS: {
    description: `For infielders with emphasis on operability. A model that enables a smooth flow from catching to sending the ball.`,
    size: 11.14
  },
  APL_UR004_PLUS: { description: `U-back model with a slightly wider palm`, size: 11.0 },
  APL_UR005_PLUS: {
    description: `When catching the ball, he is the type who grips it firmly. He is also able to handle a strong batted ball. Suitable for shortstop and third baseman.`,
    size: 11.14
  },
  APL_UR006_PLUS: { description: 'Slightly larger BOX type, often used by professional baseball players.', size: 29.5 },
  APL_UR015_PLUS: { description: 'Deep and tightly wrapped type mold.', size: 29.0 },
  APL_UR066_PLUS: { description: 'This model is a slightly smaller version of the AKG-6 model with higher operability.', size: 29.0 },
  AGL_105: { description: 'Pitcher and all-around type.', size: 29.0 },
  AGL_501: { description: 'For smaller infielders. Smaller hand opening. This type is also suitable for youth.', size: 27.8 },
  AGL_503: { description: 'Deep and wide use type model for infielders.', size: 28.6 },
  AGL_601: { description: 'It is a larger type of box type for infielders.', size: 29.6 },
  AGL_603: { description: 'Smaller size, easy to make pockets, and easy to use for inverted singles.', size: 29.3 },
  ATR_006: { description: 'The pocket is wide horizontally and can be used for both guessing and grabbing.', size: 29.5 },
  US_INFIELDER: { description: 'Deep design. A model for players who value the feeling of gripping the ball.', size: 11.75 },

  // ATOMS_外野手用
  AKG_7: {
    description:
      'Vertical model with sharp fingers. This model has narrower pockets and less ball play, resulting in less blur to the next movement.',
    size: 12.4
  },
  AKG_JS7: { description: 'Sharp vertical model with deep pockets.', size: 12.59 },
  AKG_GT07: { description: 'NEWモデル。ポケットは深くしっかり捕れるタイプ。', size: 33.0 },
  AKG_17: { description: 'The pockets are deep and wide, and the model wraps tightly.', size: 32.8 },
  AKG_27: { description: 'Smaller for outfielders. Designed with deep pockets.', size: 31.5 },
  ATR_007: { description: 'Deep pockets. For outfielders who cannot be defeated by strong batted balls.', size: 32.5 },
  AGL_801: { description: 'For small outfielders. Lightweight with deep pockets.', size: 30.5 },
  APL_UR6: { description: 'Small but firm grip model.', size: 12.4 },
  APL_UR007_PLUS: { description: 'The pockets are deep and designed for outfielders who cannot be defeated by strong batted balls.', size: 12.79 },
  APL_UR008_PLUS: { description: 'ERM wrist type of the same type as the NEW model AKG-GT07.', size: 33.0 },
  US_OUTFIELDER: { description: 'The pockets are deep and designed for outfielders who cannot be defeated by strong batted balls.', size: 12.5 },
  // ATOMS_捕手用
  AKG_12: { description: 'Small, easy-to-use model with high operability.', size: 12.79 },
  AKG_JS2: { description: 'This model is shallow and allows for a smooth change of ball grip. The web is designed to be stiff.', size: 12.4 },
  APL_UR002_PLUS: { description: 'Regular back and ERM wrist for easy operation', size: 12.4 },
  AKG_GT22: { description: 'NEW model. Mitt with regular back.', size: 32.5 },
  // ATOMS_一塁手用
  AKG_13: { description: 'Smaller model with high operability.', size: 12.79 },
  APL_UR003_PLUS: { description: 'Operates like a small first base mans glove', size: 12.79 },
  AKG_GT33: { description: 'NEW model. Adoption of a new type of web that enables a firm catch of the ball', size: 32.5 },
  DRAWING_CAUTION: '*The drawing and the embroidery are for illustrative purposes only and may differ from the actual product.',
  CATCH_SURFACE: 'Palm',
  BACK_SURFACE: 'Back surface',
  SELECTED_BASE_MODEL: 'Selected base model',
  TO_TOP: 'To top',
  RESET: 'Reset',
  SEARCH: 'Search',
  CONFIRM: 'Confirm',
  CONFIRM_TO_TOP: 'Do you really want to move to the top page?',
  CONFIRM_RESET: `Are you sure you want to reset? \nAll settings will be reset.`,
  CONFIRM_SEND_EMAIL: `Data is saved and a storage ID is sent to the email address.`,
  CONFIRM_START_SIMULATION: `Start the simulation based on `,
  PARTS_SETTING: 'Parts',
  COLOR_SETTING: 'Color',
  EMBROIDERY_SETTING: 'Embroidery',
  BASE_MODEL: 'Base model',
  KINDS: 'Kinds',
  TANNER: 'Tanner',
  DOMINANT_ARM: 'Throwing hard',
  BACK_STYLE: 'Back style',
  MATERIAL_PACK: 'Heal style',
  FINGER_SIZE: 'Finger size',
  BACK_LACE: 'Back shell lacing',
  WEB: 'Web style',
  WEB2: 'Web style 2',
  FINGER_GUARD: 'Finger guard',
  MOLDING_ORDER: 'Molding Process',
  FINGER_TIP_OUT: 'Finger tip out',
  STAND: 'Stand',
  TARGET: 'Target',
  PARTS: 'Parts',
  COLOR: ' color',
  LACE: 'Lace',
  STITCH: 'Stitch',
  HAMIDASHI: 'Welting',
  MOUTON: 'Fur',
  LABEL: 'Label',
  THUMB: 'Thumb',
  THUMB1: 'Thumb 1',
  THUMB2: 'Thumb 2',
  THUMB_ROOT: 'Thumb root',
  THUMB_INDEX_MIDDLE5: 'Thumb / Index / Middle5',
  INDEX: 'Index ',
  INDEX3: 'Index 3',
  INDEX4: 'Index 4',
  MIDDLE: 'Middle ',
  MIDDLE5: 'Middle 5',
  MIDDLE6: 'Middle 6',
  RING: 'Ring ',
  RING7: 'Ring 7',
  RING8: 'Ring 8',
  LITTLE: 'Pinky',
  LITTLE9: 'Pinky 9',
  LITTLE10: 'Pinky 10',
  THUMB2_INDEX3: 'Thumb2 / Index3',
  THUMB2_INDEX3_MIDDLE4: 'Thumb2 / Index3 / Middle4',
  THUMB2_INDEX_MIDDLE5: 'Thumb2 / Index / Middle5',
  THUMB2_INDEX_MIDDLE_RING7: 'Thumb2 / Index3,4 / Middle5,6 / Ring7',
  INDEX4_MIDDLE5: 'Index4・Middle5',
  MIDDLE5_RING: 'Middle5 / Ring',
  MIDDLE5_RING6: 'Middle5 / Ring6',
  MIDDLE6_RING7: 'Middle6 / Ring7',
  MIDDLE6_RING_PINKY: 'Middle6 / Ring / Pinky',
  RING7_LITTLE8: 'Ring7 / Pinky8',
  RING8_LITTLE9: 'Ring8 / Pinky9',
  BELT: 'Belt',
  BAND: 'Band',
  UNDER_WEB: 'Under web',
  BOOMERANG: 'boomerang',
  LOOP_OF_RING: 'Loop of ring',
  POSITION: 'Position',
  EMBROIDERY_CONTENTS: 'Contents',
  ENTER_EMBROIDERY_CONTENTS: 'Enter embroidery contents',
  TYPE_FACE: 'Font',
  MAIN_COLOR: 'Font color',
  POINT_COLOR: 'Point color',
  SHADOW_COLOR: 'Shadow color',
  EDGE_COLOR: 'Frame color',
  EDGE: 'Binding',
  THUMB_HOOK: 'Thumb felt loops',
  LITTLE_HOOK: 'Pinky felt loops',
  LININGS: 'Palm liner',
  MAX_NUMBER_OF_CHAR: 'Max of Characters',
  CHARACTERS: '',
  BASE_ITEMS: 'Base Settings',
  COLOR_ITEMS: 'Color Settings',
  EMBROIDERY_ITEMS: 'Embroidery Settings',
  NO_EMBROIDERY: 'No embroidery',
  BASE_PRICE: 'Base: ',
  EMBROIDERY_PRICE: 'Embroidery: ',
  OPTION_PRICE: 'Option: ',
  TOTAL_PRICE: 'Total: ',
  MONEY_UNIT: '$',
  SIZE_UNIT: ' inch',
  COPY: 'Copy',
  COPIED: 'Copied!',
  SAVED_ID: 'Saved ID',
  SAVE_CAUTION: 'Please save the simulation content in advance by taking a screenshot of the screen shown here.',
  CUSTOMER_INFORMATION: 'Customer Information',
  CUSTOMER_INFORMATION_CAUTION: 'After selecting all items, you can enter your information.',
  DUPLICATE_EMBROIDERY_POSITION_CAUTION: 'Embroidery positions are duplicated. Please check again to ensure there are no duplicates.',
  LEAGUE: 'League',
  FIELD_POSITION: 'Defensive position',
  EMAIL_ADDRESS: 'Email Address',
  EMAIL_ADDRESS_RETYPE: 'Email Address ( retype )',
  SAVE_BUTTON: 'Save data',
  NOW_SAVING: 'Saving...',
  RETAIL_LOGIN_PAGE_HEADER: 'Login page for retailers',
  RETAIL_LOGIN_PAGE_EXPLAIN: 'When ordering, retail staff should log in and search for your simulation results with your ID',
  LOGIN: 'Log in',
  NOW_LOGING: 'Now loging...',
  NOW_LOADING: 'Now loading...',
  BACK_TO_TOP: 'Back to top',
  PASSWORD: 'Password',
  RESET_PASSWORD: 'Reset Password',
  PAGE_OF_RESET_PASSWORD: 'Password reset page',
  DESCRIPTION_OF_HOW_TO_RESET_PASSWORD: 'Please enter your e-mail address and press the submit button.',
  SEND_RESET_EMAIL: 'Send reset email',
  TO_RETAIL_LOGIN_PAGE: 'To retaile login page',
  CAUTION_SENT_RESET_EMAIL: 'Sent. If you do not receive it, please check to see if it has been treated as spam.',
  SELECT_BRAND: 'Select a brand',
  SIMULATION_ID: 'Saved simulation ID',
  ENTER_ID: 'Please enter ID',
  LOGOUT: 'Log out',
  RETAIL_CAUTION_1: 'This brand is not available and cannot be searched.',
  RETAIL_CAUTION_2: 'The searched simulation ID was not found for the selected brand.Choose the right brand.',
  NAME: 'Name',
  TEL: 'TEL',
  REMARK: 'Remark',
  CUSTOMER_FORM_CAUTION_1: '*Prices are subject to change without notice.',
  CUSTOMER_FORM_CAUTION_2: '*We may not be able to meet your request in the remarks column.',
  CUSTOMER_FORM_CAUTION_3: '*Please also contact your agency representative for special requests.',
  TANNER_OBJECTS: tannerObjs,
  DOMINANT_ARM_OBJECTS: dominantArmEnObjs,
  BACK_STYLE_OBJECTS: backStyleEnObjs,
  BACK_STYLE_FOR_PITCHER_OBJECTS: backStyleForPitcherEnObjs,
  BACK_STYLE_FOR_CATCHER_OBJECTS: backStyleForCatcherMittEnObjs,
  RESELECT_LABEL_BEFORE_BACK_STYLE: 'To change it, first reselect the ATOMS label from L, M, N, O, P, Q, R, Z.',
  FINGER_GUARD_TYPE_DESCRIPTION: 'Please select the back style first.',
  MATERIAL_PACK_OBJECTS: materialPackEnObjs,
  SIZE_OBJECTS: sizeEnObjs,
  MOLDING_OBJECTS: moldingEnObjs,
  LOOP_OF_RING_FINGER_OBJECTS: loopOfRingFingerEnObjs,
  LOOP_OF_RING_FINGER_DESCRIPTION: 'Option: 3,300 yen',
  BACK_LACE_OBJECTS: backLaceEnObjs,
  FINGER_GUARD_TYPE_FOR_OUTFIELDER_OBJECTS: fingerGuardTypeForOutfielderEnObjs,
  FINGER_GUARD_TYPES_FOR_FIRST_BASEMAN: fingerGuardForFirstMittEnObjs,
  FINGER_GUARD_TYPE_OBJECTS: fingerGuardTypeEnObjs,
  FINGER_GUARD_TYPE_ALL_OBJECTS: fingerGuardTypeAllEnObjects,
  CAUTION_TANNER: '*Applies to the color of all parts.',
  CAUTION_DOMINANT_ARM: 'The simulation is drawn for a right-handed thrower',
  CAUTION_SIZE: 'Option: 3,300 yen',
  CAUTION_MOLDING_ORDER: 'Option: 8,800 yen',
  // CAUTION_MATERIAL_PACK: 'The shape of the bank of the "catch basin" will change.',
  CAUTION_LACE: `with inside white color has a white cross section. \nIt is not reflected in the simulation.`,
  CAUTION_BACK_STYLE: 'To change, set the finger cover to "none" once.',
  LACE_COLOR_OBJECTS: laceColorEnObjs,
  EDGE_COLOR_OBJECTS: leatherColorObjsEn,
  STITCH_COLOR_OBJECTS: stitchColorEnObjs,
  HAMIDASHI_COLOR_OBJECTS: hamidashiEnObjs,
  HAMIDASHI_COLOR_ALL_OBJECTS: hamidashiAllEnObjs,
  HAMIDASHI_COLOR_FOR_CATCHER_OBJECTS: hamidashiForCatcherEnObjs,
  MOUTON_COLOR_OBJECTS: listLiningsMaterialEnObjs,
  LABEL_COLOR_OBJECTS: atomsLabelEnObjs,
  ERM_LABEL_COLOR_OBJECTS: atomsERMLabelEnObjs,
  SELECT_BACK_STYLE_DESCRIBE: 'Please select the back style first.',
  LEATHER_COLOR_OBJECTS_BY_TANNER: leatherColorEnObjesByTanner,
  // dispatchここから
  DISPATCH_COLOR_OBJECTS: dispatchEnObjects,
  DISPATCH_EUROPEAN_LEATHER_COLOR_OBJECTS: dispatchEuropeanSteerEn,
  DISPATCH_NORTH_LEATHER_COLOR_OBJECTS: dispatchEnObjects, // must be changed!!!!!
  DISPATCH_STEER_LEATHER_COLOR_OBJECTS: dispatchEnObjects, // must be changed!!!!!
  DISPATCH_US_LEATHER_COLOR_OBJECTS: dispatchEnObjects,
  // dispatchここまで

  CATCH_FACE_OBJECTS: catchFacePartsEnObjs,
  CATCH_FACE_FOR_CATCHER_OBJECTS: catcherMittCatchFaceEnObjs,
  // 背面パーツここから
  FIRST_BACK_OBJECTS: gloveFirstBackPartsEnObjs,
  CROWN_BACK_OBJECTS: gloveCrownBackPartsEnObjs,
  BELT_BACK_OBJECTS: gloveBeltBackPartsEnObjs,
  US_MODEL_BACK_OBJECTS: gloveUsBackPartsEnObjs,
  UBACK_OBJECTS: uBackEnObjs,
  NO_BELT_BACK_OBJECTS: gloveBeltBackPartsNoBeltEnObjs,
  C_MITT_REGULAR_BACK_OBJECTS: catcherMittBackofRegularBackStylePartsEnObjs,
  C_MITT_BELT_BACK_OBJECTS: catcherMittBackofBeltBackStylePartsEnObjs,
  C_MITT_BACK_OBJECTS: catcherMittBackPartsEnObjs,
  F_MITT_NO_BELT_BACK_OBJECTS: firstMittBackPartsNoBeltEnObjs,
  F_MITT_BACK_OBJECTS: firstMittBackPartsEnObjs,
  EMBROIDERY_POSITION_OBJECTS: positionEnObjs,
  EMBROIDERY_TYPEFACE_OBJECTS: typeFaceEnAllObjs,
  EMBROIDERY_COLOR_OBJECTS: colorEnObjs,
  EMBROIDERY_SHADOW_EDGE_COLOR_OBJECTS: shadowEdgeColorEnObjs,
  CAUTION_EMBROIDERY_POSITION: 'If you want to change the embroidery position, please reset of embroidery content',
  CAUTION_EMBROIDERY_TYPEFACE: 'Please note that the actual font may differ from displayed on the screen',
  CAUTION_EMBROIDERY_CONTENTS: 'Please select "Embroidery Position."',
  CAUTION_EMBROIDERY_SHADOW_EDGE_COLOR: 'Please select "Embroidery Color."',
  DESCRIBE_EMBROIDERY_PLUS_BUTTON: 'If you want 2 embroidery places, click "+" here to display Embroidery 2 and enter it.',
  DESCRIBE_EMBROIDERY_MINUS_BUTTON: 'If you want to remove 1 place after you entered embroidery 2, please click”-“.',
  NO_SELECTION: 'No Selection',
  INITIAL_STATE_C_MITT: initialStateCatcherMittEn,
  INITIAL_STATE_F_MITT: initialStateFirstMittEn,
  INITIAL_STATE_INFIELDER: initialInfielderStateEn,
  INITIAL_STATE_OUTFIELDER: initialOutfielderStateEn,
  INITIAL_STATE_PITCHER: initialPitcherStateEn,
  INITIAL_STATE_US_GLOVE: initialUsGloveStateEn,
  INITIAL_STATE_YOUTH: initialYouthState, // US版は無いため、jaのobjectを流用
  TO_OTHER_LANGUAGES_PAGE: '日本語ページへ →',
  SENDING_MAIL: 'Sending mail ...',
  SEND_MAIL_SUCCESSFULL: 'Complete!',
  LEAGUE_OPTIONS: leagueEnOptions,
  POSITION_OPTIONS: positionEnOptions,
  BASE_PRICE_GLOVE: 71500,
  BASE_PRICE_MITT: 71500,
  MAIN_COLOR_PRICE: 2420, // 税込
  SHADOW_COLOR_PRICE: 2970, // 税込
  EDGE_COLOR_PRICE: 4620, // 税込
  AHN_JAE_SEOK_MODEL: { description: '', size: 0, productNumber: 'Ahn Jae Seok Model' },
  SHIN_JUNG_WOO_MODEL: { description: '', size: 0, productNumber: 'Shin Jung Woo Model' },
  PARK_GE_BUM_MODEL: { description: '', size: 0, productNumber: 'Park Ge Bum Model' },
  HAFN_DONG_HEE_MODEL: { description: '', size: 0, productNumber: 'Hafn Dong Hee Model' },
  LANGUAGE: 'Language',
  WEB2_PARTS_OBJECT: { value: 'web2', label: 'Web2', order: 3 },
  EMBROIDERY_CONTENT_EMOJI_CAUTION: 'Only ⭐︎,🩷, can be embroidered as pictograms and symbols'
}
