import { State, Action } from 'src/types'
import {
  SET_SELECTED_PARTS,
  SET_ALL_LEATHER_COLOR,
  SET_BASE_MODEL,
  SET_DOMINANT_ARM,
  SET_LEATHER_COLOR,
  SET_EDGE_COLOR,
  SET_LININGS,
  SET_HAMIDASHI,
  SET_FINGER_GUARD_TYPE,
  SET_STITCH_COLOR,
  SET_EMBROIDERIES,
  ADD_EMBROIDERY,
  REMOVE_EMBROIDERY,
  SET_WEB_COLOR,
  SET_THUMB_HOOK,
  SET_LITTLE_HOOK,
  SET_INDEX_COVER_COLOR,
  SET_PERSONAL,
  SET_LACE_COLOR,
  SET_ATOMS_LABEL,
  SET_SIZE,
  SET_INDEX_AND_MIDDLE_COLOR,
  SET_FINGER_STAND_COLOR,
  SET_THUMB_COLOR,
  SET_LIST_BELT_COLOR,
  SET_MIDDLE_RING_COLOR,
  SET_LITTLE_RING_COLOR,
  SET_LITTLE_OUT_COLOR,
  SET_MIDDLE_LEFT_RING,
  SET_MIDDLE_LEFT_RING_LITTLE,
  SET_THUMB_INDEX_MIDDLE_RIGHT,
  SET_MOUTON_COLOR,
  SET_CATCH_FACE_TARGET_COLOR,
  SET_INDEX_FINGER_TIP_OUT_COLOR,
  SET_BACK_STYLE,
  SET_TANNER,
  SET_FIGURE_PANEL_NUMBER
} from 'src/features/brands/atoms/japan/constants/actionType'
import { colorInitialState, initialEmbroideryState } from 'src/features/brands/atoms/japan/reducer'
import { leatherColorObjsJa } from 'src/features/brands/atoms/japan/constants/colors'
import {
  baseInitialState,
  initialPersonalState,
  unselectedState,
  unselectedColorState,
  unselectedStateEn,
  unselectedColorStateEn,
  baseInitialStateEn,
  colorInitialStateEn,
  unselectedStateKo,
  unselectedColorStateKo,
  baseInitialStateKo,
  colorInitialStateKo
} from 'src/features/brands/reducer'

// 捕手用ミット
export const initialStateCatcherMitt: State = {
  ...baseInitialState,
  ...colorInitialState,
  kinds: unselectedState,
  tanner: unselectedState,
  backStyle: unselectedState,
  thumb: unselectedColorState,
  thumbIndexMiddleRight: unselectedColorState,
  catchFaceTarget: unselectedColorState,
  indexAndMiddle: unselectedColorState,
  middleRing: unselectedColorState,
  middleLeftRingLittle: unselectedColorState,
  middleLeftRing: unselectedColorState,
  littleRing: unselectedColorState,
  littleOut: unselectedColorState,
  fingerStand: unselectedColorState,
  indexFingerTipOut: unselectedColorState,
  mouton: unselectedState,
  atomsLabel: unselectedState,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}

export const initialStateCatcherMittEn: State = {
  ...baseInitialStateEn,
  ...colorInitialStateEn,
  tanner: unselectedStateEn,
  backStyle: unselectedStateEn,
  thumb: unselectedColorStateEn,
  thumbIndexMiddleRight: unselectedColorStateEn,
  catchFaceTarget: unselectedColorStateEn,
  indexAndMiddle: unselectedColorStateEn,
  middleRing: unselectedColorStateEn,
  middleLeftRingLittle: unselectedColorStateEn,
  middleLeftRing: unselectedColorStateEn,
  littleRing: unselectedColorStateEn,
  littleOut: unselectedColorStateEn,
  fingerStand: unselectedColorStateEn,
  indexFingerTipOut: unselectedColorStateEn,
  mouton: unselectedStateEn,
  atomsLabel: unselectedStateEn,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}
export const initialStateCatcherMittKo: State = {
  ...baseInitialStateKo,
  ...colorInitialStateKo,
  tanner: unselectedStateKo,
  backStyle: unselectedStateKo,
  thumb: unselectedColorStateKo,
  thumbIndexMiddleRight: unselectedColorStateKo,
  catchFaceTarget: unselectedColorStateKo,
  indexAndMiddle: unselectedColorStateKo,
  middleRing: unselectedColorStateKo,
  middleLeftRingLittle: unselectedColorStateKo,
  middleLeftRing: unselectedColorStateKo,
  littleRing: unselectedColorStateKo,
  littleOut: unselectedColorStateKo,
  fingerStand: unselectedColorStateKo,
  indexFingerTipOut: unselectedColorStateKo,
  mouton: unselectedStateKo,
  atomsLabel: unselectedStateKo,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}

export const catcherReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TANNER:
      return { ...state, tanner: action.tanner }
    case SET_SELECTED_PARTS:
      return { ...state, parts: action.parts }
    case SET_ALL_LEATHER_COLOR:
      return {
        ...state,
        all: action.all,
        catchFace: action.all,
        web: action.all,
        thumbHook: action.all,
        littleHook: action.all,
        edge: action.all,
        indexCover: action.all,
        // indexAndThumb: action.all,
        listBelt: action.all,
        // 裏革にはない色が「全体」として指定されたら、裏革のみ既存stateを維持させる
        // 裏革にもある色が「全体」として指定されたらもちろん反映を許可
        linings: leatherColorObjsJa.some((x) => x.color === action.all.color) ? action.all : state.linings, // 裏革
        // 捕手用ミット
        thumb: action.all,
        thumbIndexMiddleRight: action.all,
        catchFaceTarget: action.all,
        indexAndMiddle: action.all,
        fingerStand: action.all,
        indexFingerTipOut: action.all,
        middleRing: action.all,
        middleLeftRing: action.all,
        middleLeftRingLittle: action.all,
        littleRing: action.all,
        littleOut: action.all
      }
    case SET_BASE_MODEL:
      return { ...state, baseModel: action.baseModel, kinds: action.kinds }
    case SET_DOMINANT_ARM:
      return { ...state, dominantArm: action.dominantArm }
    case SET_SIZE:
      return { ...state, size: action.size }
    case SET_LEATHER_COLOR:
      return { ...state, catchFace: action.catchFace }
    case SET_EDGE_COLOR:
      return { ...state, edge: action.edge }
    case SET_WEB_COLOR:
      return { ...state, web: action.web }
    // 捕手用ミット
    case SET_BACK_STYLE:
      return { ...state, backStyle: action.backStyle, figurePanelNum: 1 }
    case SET_LIST_BELT_COLOR:
      return { ...state, listBelt: action.listBelt }
    case SET_THUMB_COLOR:
      return { ...state, thumb: action.thumb }
    case SET_THUMB_INDEX_MIDDLE_RIGHT:
      return { ...state, thumbIndexMiddleRight: action.thumbIndexMiddleRight }
    case SET_CATCH_FACE_TARGET_COLOR:
      return { ...state, catchFaceTarget: action.catchFaceTarget }
    case SET_INDEX_AND_MIDDLE_COLOR:
      return { ...state, indexAndMiddle: action.indexAndMiddle }
    case SET_FINGER_STAND_COLOR:
      return { ...state, fingerStand: action.fingerStand }
    case SET_INDEX_FINGER_TIP_OUT_COLOR:
      return { ...state, indexFingerTipOut: action.indexFingerTipOut }
    case SET_MIDDLE_RING_COLOR:
      return { ...state, middleRing: action.middleRing }
    case SET_MIDDLE_LEFT_RING_LITTLE:
      return { ...state, middleLeftRingLittle: action.middleLeftRingLittle }
    case SET_MIDDLE_LEFT_RING:
      return { ...state, middleLeftRing: action.middleLeftRing }
    case SET_LITTLE_RING_COLOR:
      return { ...state, littleRing: action.littleRing }
    case SET_LITTLE_OUT_COLOR:
      return { ...state, littleOut: action.littleOut }
    case SET_LININGS:
      return { ...state, linings: action.linings, figurePanelNum: 1 }
    case SET_HAMIDASHI:
      return { ...state, hamidashi: action.hamidashi, figurePanelNum: 1 }
    case SET_FINGER_GUARD_TYPE:
      return { ...state, fingerGuardType: action.fingerGuardType, figurePanelNum: 1 }
    case SET_LACE_COLOR:
      return { ...state, lace: action.lace }
    case SET_ATOMS_LABEL:
      return { ...state, atomsLabel: action.atomsLabel, figurePanelNum: 1 }
    case SET_STITCH_COLOR:
      return { ...state, stitch: action.stitch }
    case SET_EMBROIDERIES:
      return { ...state, embroideries: action.embroideries, figurePanelNum: 1 }
    case ADD_EMBROIDERY: // incrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case REMOVE_EMBROIDERY: // decrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case SET_THUMB_HOOK:
      return { ...state, thumbHook: action.thumbHook, figurePanelNum: 1 }
    case SET_LITTLE_HOOK:
      return { ...state, littleHook: action.littleHook, figurePanelNum: 1 }
    case SET_INDEX_COVER_COLOR:
      return { ...state, indexCover: action.indexCover }
    case SET_MOUTON_COLOR:
      return { ...state, mouton: action.mouton }
    case SET_PERSONAL:
      return { ...state, personal: action.personal }
    case SET_FIGURE_PANEL_NUMBER:
      return { ...state, figurePanelNum: action.figurePanelNum }
    default:
      return state
  }
}
