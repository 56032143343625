import { positionTranslate } from 'src/locales/constants'

export const dominantArmKoObjs = [
  { label: '우투(왼손착용)', value: 'rightThrow' },
  { label: '좌투(오른손착용)', value: 'leftThrow' }
]
export const sizeKoObjs = [
  { label: '10mm 크게', value: 'large10', price: 3300 },
  { label: '5mm 크게', value: 'large5', price: 3300 },
  { label: '변경없음', value: 'default', price: 0 },
  { label: '5mm 작게', value: 'small5', price: 3300 },
  { label: '10mm 작게', value: 'small10', price: 3300 }
]
export const backLaceKoObjs = [
  { label: '있음', value: 'straight', imageUrl: '' },
  { label: '없음', value: 'none', imageUrl: '' }
]
export const backStyleKoObjs = [
  { label: '벨트', value: 'beltBackStyle' },
  { label: '패스트백', value: 'firstBackStyle' },
  { label: '왕관', value: 'crownBackStyle' },
  { label: '레귤러', value: 'regularBackStyle' }
]

export const backStyleForPitcherKoObjs = [
  { label: '벨트', value: 'beltBackStyle', imageUrl: '' }
  // { label: '패스트백', value: 'firstBackStyle' },
  // { label: '왕관', value: 'crownBackStyle' }
]
export const backStyleForCatcherMittKoObjs = [
  { label: '벨트', value: 'beltBackStyle', imageUrl: '' },
  { label: '레귤러', value: 'regularBackStyle', imageUrl: '' }
]
export const fingerGuardTypeAllKoObjects = [
  { label: '손가락 커버 있음', value: 'indexCover', imageUrl: '' },
  { label: '손가락 패드 있음(검지)', value: 'indexPad', imageUrl: '' },
  { label: '손가락 패드 있음(중지)', value: 'middlePad', imageUrl: '' },
  { label: '없음', value: 'none', imageUrl: '' }
]
export const fingerGuardTypeKoObjs = [
  { label: '손가락 커버 있음', value: 'indexCover', imageUrl: '' },
  { label: '손가락 패드 있음(검지)', value: 'indexPad', imageUrl: '' },
  { label: '없음', value: 'none', imageUrl: '' }
]
export const fingerGuardTypeForFirstMittKoObjects = [
  { label: '손가락 패드 있음(검지)', value: 'indexPad', imageUrl: '' },
  { label: '없음', value: 'none', imageUrl: '' }
]

export const fingerGuardTypeForOutfielderKoObjs = fingerGuardTypeAllKoObjects
export const materialPackKoObjs = [
  // { label: '종방향', value: 'vertical' }, // 2023/12/20、下記の表記に変更希望
  // { label: '횡방향', value: 'sideDouble' }
  { label: '레디얼', value: 'vertical', imageUrl: '' },
  { label: '기본', value: 'sideDouble', imageUrl: '' }
]

export const moldingKoObjs = [
  { label: '글러브가공', value: 'moldingProcess', price: 8800 }, //$36
  { label: '없음', value: 'none', price: 0 }
]

export const loopOfRingFingerKoObjs = [
  { label: '링 있음', value: 'loopOfRingFinger', price: 3300, imageUrl: '' },
  { label: '없음', value: 'none', price: 0, imageUrl: '' }
]

export const ko2JaBasePartsTranslates = [
  { korea: '종별', japanese: '種別' },
  { korea: '기본 모델', japanese: '基本モデル' },
  { korea: '가죽 선택', japanese: 'タンナー' },
  { korea: '우투/좌투', japanese: '利き腕' },
  { korea: '웹', japanese: 'ウェブ' },
  { korea: '손가락 커버 및 패드', japanese: '指カバー/指当て' },
  { korea: '심 스타일', japanese: '芯とじスタイル' },
  { korea: '손가락 길이', japanese: '指の長さ' },
  { korea: '배면(뒷면)의 통과 끈', japanese: '背面紐通し' },
  { korea: '우라가미 가죽', japanese: '浦上レザー' },
  { korea: '우투', japanese: '右投げ' },
  { korea: '우투(왼손착용)', japanese: '右投げ' },
  { korea: '좌투(오른손착용)', japanese: '左投げ' },
  { korea: '10mm 크게', japanese: '10mm大きく' },
  { korea: '5mm 크게', japanese: '5mm大きく' },
  { korea: '변경없음', japanese: '基本モデルズバリ' },
  { korea: '5mm 작게', japanese: '5mm小さく' },
  { korea: '10mm 미리 작게', japanese: '10mm小さく' },
  { korea: '있음', japanese: 'あり' },
  { korea: '벨트', japanese: 'ベルト' },
  { korea: '패스트백', japanese: 'ファーストバック' },
  { korea: '왕관', japanese: 'ウィングチップ' },
  { korea: '레귤러', japanese: 'レギュラーバック' },
  { korea: '손가락 커버 있음', japanese: '指カバー有り' },
  { korea: '손가락 패드 있음(검지)', japanese: '指アテ有り(人差し指)' },
  { korea: '손가락 패드 있음(중지)', japanese: '指アテ有り(中指)' },
  { korea: '없음', japanese: 'なし' },
  { korea: '레디얼', japanese: 'たてとじ' },
  { korea: '기본', japanese: 'よことじ' },
  { korea: '약지 링', japanese: '薬指リング' },
  { korea: '백스타일', japanese: 'バックスタイル' },
  { korea: '안재석 선수', japanese: 'アン・ジェソクモデル' },
  { korea: '신준우 선수', japanese: 'シン・ジュンウモデル' },
  { korea: '박계범 선수', japanese: 'パク・ゲボムモデル' },
  { korea: '한동희 선수', japanese: 'ハン・ドンヒモデル' },
  { korea: '글러브가공', japanese: '型付け有り' },
  { korea: '고교야구', japanese: '高校野球' },
  { korea: '프로야구', japanese: 'プロ野球' },
  { korea: '사회인야구', japanese: '社会人野球' },
  { korea: '대학야구', japanese: '大学野球' },
  { korea: '취미', japanese: '草野球' },
  ...positionTranslate
  // { korea: '', japanese: '' },
]
