import {
  atomsERMLabelKoObjs,
  atomsLabelKoObjs,
  catcherMittBackofBeltBackStylePartsKoObjs,
  catcherMittBackofRegularBackStylePartsKoObjs,
  catcherMittCatchFaceKoObjs,
  catchFacePartsKoObjs,
  dispatchKoObjects,
  gloveBeltBackPartsKoObjs,
  gloveBeltBackPartsNoBeltKoObjs,
  gloveCrownBackPartsKoObjs,
  gloveFirstBackPartsKoObjs,
  gloveUsBackPartsKoObjs,
  hamidashiAllKoObjs,
  hamidashiForCatcherKoObjs,
  hamidashiKoObjs,
  infielderBackPartsForUBackKoObjs,
  laceColorKoObjs,
  leatherColorKoObjesByTanner,
  leatherColorKoObjs,
  listLiningsMaterialKoObjs,
  stitchColorKoObjs,
  tannerKoObjs
} from 'src/features/brands/atoms/korea/constants/colors'
import {
  backLaceKoObjs,
  backStyleForCatcherMittKoObjs,
  backStyleForPitcherKoObjs,
  backStyleKoObjs,
  dominantArmKoObjs,
  fingerGuardTypeAllKoObjects,
  fingerGuardTypeForFirstMittKoObjects,
  fingerGuardTypeForOutfielderKoObjs,
  fingerGuardTypeKoObjs,
  loopOfRingFingerKoObjs,
  materialPackKoObjs,
  moldingKoObjs,
  sizeKoObjs
} from 'src/features/brands/atoms/korea/constants/bases'
import { colorKoObjs, positionKoObjs, shadowEdgeColorKoObjs, typeFaceKoObjs } from 'src/features/brands/atoms/korea/constants/embroidery'
import { initialStateCatcherMittKo } from 'src/features/brands/atoms/japan/reducer/catcher'
import { initialStateFirstMittKo } from 'src/features/brands/atoms/japan/reducer/first'
import { initialOutfielderStateKo } from 'src/features/brands/atoms/japan/reducer/outfielder'
import { initialPitcherStateKo } from 'src/features/brands/atoms/japan/reducer/pitcher'
import { leagueKoOptions, positionKoOptions } from 'src/locales/constants'
import { initialYouthState } from 'src/features/brands/atoms/japan/reducer/youth'
import { initialUsGloveStateKo } from 'src/features/brands/atoms/us/reducer/glove'
import { initialInfielderStateKo } from 'src/features/brands/atoms/korea/reducer/infielder'

export default {
  SIMULATABLE_BRANDS: '시뮬레이션 가능한 브랜드',
  ORDER_SIMULATION: '주문 시뮬레이션',
  IF_START_SIMULATION: ``,
  IF_START_SIMULATION_2: ``,
  START_BUTTON: '이용약관에 동의하고 시뮬레이션 개시',
  CHECK_SIMULATION: '시뮬레이션 확인',
  TERMS_OF_USE: '이용 규약',
  TERMS_OF_USE_ITEM_1: '본 시스템은, ATOMS 그룹의 주문 그랩의 슈레미션을 가능하게 하는 것을 목적으로 디자인되었습니다.',
  TERMS_OF_USE_ITEM_2: '일부 디자인이나 컬러가 실제 상품과 다를 수 있습니다.',
  TERMS_OF_USE_ITEM_3: '고교야구 등 연맹별로 사용제한이 있으므로 각자 확인하시기 바랍니다.',
  TERMS_OF_USE_ITEM_4: '시뮬레이션 시 발행되는 그랩 ID의 부정사용에 대하여 당사는 어떠한 책임도 지지 않습니다.',
  TERMS_OF_USE_ITEM_5: '이용된 고객의 프라이버시를 지키기 위해 암호화 등의 방법으로 정보 보호에 힘씁니다.',
  TERMS_OF_USE_ITEM_6:
    '본 시스템의 이용은 고객의 책임 하에 이루어집니다. 해당 시스템 및 해당 시스템에 비링크 되어 있는 다른 웹사이트로부터 취득된 각종 정보의 이용으로 인해 발생한 모든 손해에 관하여 당사는 어떠한 책임도 지지 않습니다.',
  TERMS_OF_USE_ITEM_7: '당사 시스템은 적절히 재검토하여 개정하는 일이 있습니다.',
  TRANSLATE_CAUTION_DESCRIBE: '한국어로 시스템을 계속 이용할 경우 구글의 자동번역 기능을 OFF할 것을 권장합니다.',
  FOR_DETAIL: '자세한 것은 이쪽↓',
  HOW_TO_OFF_GOOGLE_AUTO_TRANSLATE: 'Google Chrome의 자동번역 기능을 OFF로 하는 방법',
  DISABLE_MESSAGE_EVER: '앞으로 이 메시지를 표시하지 않겠다.',
  CLOSE: '닫기',
  PRODUCT_PAGE: '製品제품 페이지',
  HARD_GLOVE_MITT: '경식 글로브 미트',
  HARD: '경식',
  SOFT_BALL_GLOVE_MITT: '연식 글로브 미트',
  SOFT: '연식',
  SPIKES: '스파이크',
  BAT: '배트, 타봉',
  GLOVE: '글로브',
  MITT: '미트',
  COMMING_SOON: '(준비중)',
  BRAND_SELECTION_PAGE: '브랜드 선택 페이지',
  POSITION_SELECTION_PAGE: '포지션 선택 페이지',
  SERIES_SELECTION_PAGE: '시리즈 선택 페이지',
  PITCHER: '투수용',
  CATCHER: '포수용',
  INFIELDER: '내야수용',
  OUTFIELDER: '외야수용',
  FIRST_BASEMAN: '일루수용',
  JAPAN_SERIES: '일본 모델',
  USA_SERIES: '아메리칸 모델',
  KOREA_SERIES: '한국 모델',
  PLEASE_SELECT_MODEL: '기본 모델을 선택하세요.',
  PRODUCT_NUMBER: '모델명：',
  START_SIMULATION: '시뮬레이션을 개시',
  // ATOMS_投手用
  AGL_NE001: { description: '', size: 30.0 },
  AGL_NE011: { description: '', size: 29.8 },
  AKG_1: { description: '전통적인 투수용.주머니는 깊고 옆으로 흔드는 모델.', size: 30.0 },
  AKG_11: { description: '몸집이 작은 투수용필딩에도 뛰어난 세로형 모델.', size: 29.0 },
  ATR_001: { description: '깊이로 세로형, 단단히 잡을 수 있는 투수용.', size: 29.0 },
  AKG_21: { description: 'ATOMS 투수용 최초의 세로형 세로철 채용 글러브', size: 29.8 },
  AKG_PRO46: { description: '오릭스 버펄로스 혼다 히토미 선수 모델', size: 29.0 },
  US_PITCHER: { description: '주머니가 깊은 타입. 깊고 세로형, 단단히 잡을 수 있는 투수용.', size: 12.0 },
  // ATOMS_内野手用
  AGL_NE102: { description: '', size: 28.3 },
  AGL_NE105: { description: '', size: 29.0 },
  AGL_NE501: { description: '', size: 27.8 },
  AGL_NE801: { description: '', size: 30.5 },
  AGL_NE008: { description: '', size: 33.0 },
  AGL_NE002: { description: '', size: 32.5 },
  AGL_NE003: { description: '', size: 32.5 },
  APL_NE021: { description: '', size: 28.7 },
  APL_NE026: { description: '', size: 28.3 },
  APL_NE066: { description: '', size: 29.0 },
  APL_NE102: { description: '', size: 28.3 },
  APL_NE702: { description: '', size: 32.0 },

  AKG_JS4_PLUS: { description: '변형 자재로 어떤 타구에도 대응할 수 있는 모델.', size: 28.7 },
  AKG_JS5: { description: '둑은 좁은 확실히 붙잡고 잡는 선수용.', size: 28.3 },
  AKG_16: { description: 'ATOMS 최경량 설계.손가락이 샤프하고 조작성이 뛰어나 땅볼을 다루기 쉬운 모델.', size: 27.8 },
  AKG_4: { description: '변환 자유자재로 어떤 타구에도 대응할 수 있는 모델.', size: 28.0 },
  AKG_5: { description: '현대 야구 스타일의 초스탠다드.선수의 플레이 스타일에 틀이 잘 어울리는 모델.', size: 28.3 },
  AKG_6: { description: `내야수용으로 유일하게 '레귤러 감기'를 채용.수법의 와이드함을 줄인 모델`, size: 29.6 },
  APL_UR2: { description: '가로젓기에도 사용할 수 있는 깊은 글러브.꽉 잡는 이유간용 모델.', size: 29.0 },
  APL_UR3: { description: '가장 얕은 글러브경기용으로도 훈련용으로도 대응할 수 있는 모델.', size: 27.5 },
  APL_UR4: { description: '깊어지기 어려운 설계.포구면을 넓게 사용하고 싶은 플레이어용', size: 28.0 },
  APL_UR5: { description: '쇼트, 서드용 크기로 웹 주위는 강한 타구에도 지지 않는 설계.', size: 28.3 },
  APL_UR10: { description: 'U백에 엣지가 있는 웹 채용 모델.', size: 28.0 },
  APL_UR026_PLUS: { description: '조작성을 중시한 내야수용.포구에서 송구로의 흐름을 원활하게 할 수 있는 모델', size: 28.3 },
  APL_UR004_PLUS: { description: 'U백을 채택한 포구면이 약간 넓은 모델', size: 28.3 },
  APL_UR005_PLUS: { description: '포구시 제대로 잡고 타입 강한 타구에게도 뒤지지 않는다.쇼트 서드용', size: 28.3 },
  APL_UR006_PLUS: { description: 'ATR-006+후계 모델.프로야구 선수들도 자주 사용하는 형태로 약간 큰 BOX형.', size: 29.5 },
  APL_UR015_PLUS: { description: '깊고 단단하게 감싸는 타입.', size: 29.0 },
  APL_UR066_PLUS: { description: 'NEW 모델.AKG-6형을 조금 작게 만들어 조작성을 높인 모델.', size: 29.0 },
  AGL_105: { description: '투수 겸 올라운드 타입.', size: 29.0 },
  AGL_501: { description: '작은 내야수용.수법도 작다.사용에도 대응할 수 있는 타입.', size: 27.8 },
  AGL_503: { description: '깊고 넓게 사용할 수 있는 타입의 내야수용 모델', size: 28.6 },
  AGL_601: { description: '내야수용 중에서는 큰 타입의 박스형입니다.', size: 29.6 },
  AGL_603: { description: 'ATOMS에서는 적은 옆모습의 내야수용 모델.작고 주머니를 만들기 쉽고, 반대 싱글에도 대응하기 쉬운 타입.', size: 29.3 },
  ATR_006: { description: '주머니는 옆으로 넓어서 뺑이도 잡을 수 있는 타입.', size: 29.5 },
  US_INFIELDER: { description: '깊은 설계. 공을 잡는 감각을 중시하는 플레이어용 모델', size: 11.75 },
  // ATOMS_外野手用
  AKG_7: { description: '손가락이 날카로운 세로형. 주머니가 좁고 공놀이가 적어 다음 동작에 흔들림이 적은 모델.', size: 31.5 },
  AKG_JS7: { description: '샤프한 세로형으로 포켓은 깊은 모델.', size: 32 },
  AKG_GT07: { description: 'NEW 모델.주머니는 깊고 꽉 잡을 수 있는 타입.', size: 33.0 },
  AKG_17: { description: '주머니는 깊고 넓고, 단단히 감싸는 타입의 모델입니다.', size: 32.8 },
  AKG_27: { description: '외야수용으로는 작은 편.주머니가 깊게 파인 디자인으로 소프트볼에 대응할 수 있는 타입.', size: 31.5 },
  ATR_007: { description: '주머니는 깊고 강한 타구에도 뒤지지 않는 외야수용.', size: 32.5 },
  AGL_801: { description: '작은 외야수용가볍고 주머니가 깊은 타입.', size: 30.5 },
  APL_UR6: { description: '작지만 단단히 잡을 수 있는 모델.', size: 31.5 },
  APL_UR007_PLUS: { description: '주머니는 깊어 강한 타구에도 지지 않는 외야수용.', size: 32.5 },
  APL_UR008_PLUS: { description: 'NEW 모델 AKG-GT07 동형의 ERM 리스트 타입.', size: 33.0 },
  US_OUTFIELDER: { description: '주머니는 깊고 강한 타구에도 뒤지지 않는 외야수용', size: 12.5 },
  // ATOMS_捕手用
  AKG_12: { description: '작고 조작성도 높고 사용하기 쉬운 모델', size: 32.5 },
  AKG_JS2: { description: '얕고 볼을 교환할 수 있는 모델. 웹은 딱딱한 설계.', size: 31.5 },
  APL_UR002_PLUS: { description: '레귤러 백과 ERM 리스트로 조작성이 탁월', size: 31.5 },
  AKG_GT22: { description: 'NEW 모델.레귤러백 채용 미트.', size: 32.5 },
  // ATOMS_一塁手用
  AKG_13: { description: '조작성도 높은 작은 모델', size: 32.5 },
  APL_UR003_PLUS: { description: '소형 1루수용 글러브와 같은 조작성', size: 32.5 },
  AKG_GT33: { description: 'NEW 모델.제대로 포구할 수 있는 신형 웹 채용', size: 32.5 },
  DRAWING_CAUTION: '※글러브·미트의 그리기, 자수의 위치·서체는 이미지로 실제와 다를 수 있습니다.',
  CATCH_SURFACE: '포구면',
  BACK_SURFACE: '뒷면',
  SELECTED_BASE_MODEL: '선택 중인 기본 모델',
  TO_TOP: '홈으로',
  RESET: '취소',
  SEARCH: '검색',
  CONFIRM: '확인',
  CONFIRM_TO_TOP: '정말 첫 페이지로 이동하시겠습니까 ?',
  CONFIRM_RESET: `정말 리셋 하시겠습니까?\n모든 설정이 리셋됩니다.`,
  CONFIRM_SEND_EMAIL: `데이터를 보존하고, 메일 주소지에 저장 ID를 송부합니다.`,
  CONFIRM_START_SIMULATION: `을 바탕으로 시뮬레이션을 시작합니다`,
  PARTS_SETTING: ' 기본 설정',
  COLOR_SETTING: '컬러 설정',
  EMBROIDERY_SETTING: '자수 설정',
  BASE_MODEL: '기본 모델',
  KINDS: '종별',
  TANNER: '가죽 선택',
  DOMINANT_ARM: '우투/좌투',
  BACK_STYLE: '백스타일',
  MATERIAL_PACK: '심 스타일',
  FINGER_SIZE: '손가락 길이',
  BACK_LACE: '배면(뒷면)의 통과 끈',
  WEB: '웹',
  WEB2: '웹2',
  FINGER_GUARD: '손가락 커버 및 패드',
  MOLDING_ORDER: '글러브가공',
  FINGER_TIP_OUT: '짚다',
  STAND: '백스테이지',
  TARGET: '타깃',
  PARTS: '글러브 부위:',
  COLOR: ' 컬러',
  LACE: '레이스',
  STITCH: '스티치',
  HAMIDASHI: '웰팅',
  EDGE: '바인딩',
  THUMB_HOOK: '엄지 레이스',
  LITTLE_HOOK: '소지 레이스',
  LININGS: '내피',
  MOUTON: '양모',
  LABEL: '라벨',
  THUMB: '엄지',
  THUMB1: '엄지1',
  THUMB_INDEX_MIDDLE5: '엄지・검지・중지5',
  THUMB2: '엄지2',
  THUMB_ROOT: '엄지근본',
  INDEX: '검지',
  INDEX3: '검지3',
  INDEX4: '검지4',
  MIDDLE: '중지',
  MIDDLE5: '중지5',
  MIDDLE6: '중지6',
  RING: '약지',
  RING7: '약지7',
  RING8: '약지8',
  LITTLE: '소지',
  LITTLE9: '소지9',
  LITTLE10: '소지10',
  THUMB2_INDEX3: '엄지2・검지3',
  THUMB2_INDEX3_MIDDLE4: '엄지2・검지3・중지4',
  THUMB2_INDEX_MIDDLE5: '엄지2・검지3,4・중지5',
  THUMB2_INDEX_MIDDLE_RING7: '엄지2・검지3,4・중지5,6・약지7',
  INDEX4_MIDDLE5: '검지4・중지5',
  MIDDLE5_RING: '중지5・약지',
  MIDDLE5_RING6: '중지5・약지6',
  MIDDLE6_RING7: '중지6・약지7',
  MIDDLE6_RING_PINKY: '중지6・약지・소지',
  RING7_LITTLE8: '약지7・소지8',
  RING8_LITTLE9: '약지8・소지9',
  BELT: '벨트',
  BAND: '밴드',
  UNDER_WEB: '웹 아래',
  BOOMERANG: '부메랑',
  LOOP_OF_RING: '약지 링',
  POSITION: '위치',
  TYPE_FACE: '서체',
  MAIN_COLOR: '자수 컬러',
  POINT_COLOR: '포인트 컬러',
  SHADOW_COLOR: '그림자 컬러',
  EDGE_COLOR: '테두리 컬러',
  EMBROIDERY_CONTENTS: '자수 내용',
  ENTER_EMBROIDERY_CONTENTS: '자수 내용을 입력하세요',
  MAX_NUMBER_OF_CHAR: '최대 글자수',
  CHARACTERS: '자',
  BASE_ITEMS: '기본항목',
  COLOR_ITEMS: '칼라 항목',
  EMBROIDERY_ITEMS: '자수 항목',
  NO_EMBROIDERY: '자수 없음',
  BASE_PRICE: '기본금액：',
  EMBROIDERY_PRICE: '자수 금액：',
  OPTION_PRICE: '옵션 금액：',
  TOTAL_PRICE: '합계 금액：',
  MONEY_UNIT: '₩',
  SIZE_UNIT: ' inch',
  COPY: '복사',
  COPIED: '복사필',
  SAVED_ID: '보존ID',
  SAVE_CAUTION: '',
  CUSTOMER_INFORMATION: '주문자 정보',
  CUSTOMER_INFORMATION_CAUTION: '항목 모두 선택 후 고객정보를 입력하실 수 있습니다. 또한 한글은 자수 불가입니다.',
  DUPLICATE_EMBROIDERY_POSITION_CAUTION: '자수 위치가 중복됩니다.중복이 없도록 다시 확인해 주십시오.',
  LEAGUE: '리그',
  FIELD_POSITION: '수비 위치',
  EMAIL_ADDRESS: 'e메일 주소',
  EMAIL_ADDRESS_RETYPE: 'e메일 주소(재입력)',
  SAVE_BUTTON: '데이터 저장',
  NOW_SAVING: '저장 중...',
  RETAIL_LOGIN_PAGE_HEADER: '소매점용 로그인 화면',
  RETAIL_LOGIN_PAGE_EXPLAIN: '소매점원은 로그인 후 고객님의 시뮬레이션 결과를 아이디로 검색해 주십시오.',
  LOGIN: '로그인',
  NOW_LOGING: '로그인 중...',
  NOW_LOADING: '읽기 중...',
  BACK_TO_TOP: '톱으로 돌아가다',
  PASSWORD: '비밀번호',
  RESET_PASSWORD: '비밀번호 재설정.',
  PAGE_OF_RESET_PASSWORD: '패스워드 리셋 화면',
  DESCRIPTION_OF_HOW_TO_RESET_PASSWORD: '이메일 주소를 입력하고 보내기 버튼을 눌러주세요.',
  SEND_RESET_EMAIL: '리셋 메일 보내기',
  TO_RETAIL_LOGIN_PAGE: '소매점 로그인 화면으로',
  CAUTION_SENT_RESET_EMAIL: '보냈습니다 수신이 안되면 스팸메일 취급이 안되는지도 확인 부탁드립니다.',
  SELECT_BRAND: '브랜드 선택',
  SIMULATION_ID: '시뮬레이션 ID',
  ENTER_ID: '아이디를 입력',
  LOGOUT: '로그아웃',
  RETAIL_CAUTION_1: '해당 브랜드는 취급 대상에서 제외되어 검색이 되지 않습니다.',
  RETAIL_CAUTION_2: '검색된 시뮬레이션 ID는 선택된 브랜드에서는 찾을 수 없었습니다.올바른 브랜드를 선택하세요.',
  NAME: '성함：',
  TEL: '전화：',
  REMARK: '비고란：',
  CUSTOMER_FORM_CAUTION_1: '※ 요금은 예고없이 변경될 수 있습니다.',
  CUSTOMER_FORM_CAUTION_2: '※비고란에 기재해 주신 요청에 부응하지 못할 수 있습니다.',
  CUSTOMER_FORM_CAUTION_3: '※특별한 요망 등은 번거롭지만 대리점 담당자에게도 문의 주세요.',
  TANNER_OBJECTS: tannerKoObjs,
  DOMINANT_ARM_OBJECTS: dominantArmKoObjs,
  BACK_STYLE_OBJECTS: backStyleKoObjs,
  BACK_STYLE_FOR_PITCHER_OBJECTS: backStyleForPitcherKoObjs,
  BACK_STYLE_FOR_CATCHER_OBJECTS: backStyleForCatcherMittKoObjs,
  RESELECT_LABEL_BEFORE_BACK_STYLE: '변경하려면 먼저 L, M, N, O, P, Q, R, Z 중에서 ATOMS 라벨을 다시 선택해 주세요.',
  FINGER_GUARD_TYPE_DESCRIPTION: '백 스타일을 먼저 선택해 주세요.',
  MATERIAL_PACK_OBJECTS: materialPackKoObjs,
  SIZE_OBJECTS: sizeKoObjs,
  MOLDING_OBJECTS: moldingKoObjs,
  LOOP_OF_RING_FINGER_OBJECTS: loopOfRingFingerKoObjs,
  LOOP_OF_RING_FINGER_DESCRIPTION: 'Option: 3,300 yen',
  BACK_LACE_OBJECTS: backLaceKoObjs,
  FINGER_GUARD_TYPE_FOR_OUTFIELDER_OBJECTS: fingerGuardTypeForOutfielderKoObjs,
  FINGER_GUARD_TYPES_FOR_FIRST_BASEMAN: fingerGuardTypeForFirstMittKoObjects,
  FINGER_GUARD_TYPE_OBJECTS: fingerGuardTypeKoObjs,
  FINGER_GUARD_TYPE_ALL_OBJECTS: fingerGuardTypeAllKoObjects,
  CAUTION_TANNER: '',
  CAUTION_DOMINANT_ARM: '시뮬레이션 이미지는 우투용으로 그려집니다.',
  CAUTION_SIZE: 'Option: 3,300 yen',
  CAUTION_MOLDING_ORDER: 'Option: 8,800 yen',
  // CAUTION_MATERIAL_PACK: '「捕球面」の土手の形状が変わります。',
  CAUTION_LACE: '색상 & 화이트는 단면이 화이트입니다. 시뮬레이션에는 반영되지 않습니다.',
  CAUTION_BACK_STYLE: '변경하기 위해서는 손가락 커버를 한 번 "없음"으로 해 주세요.',
  LACE_COLOR_OBJECTS: laceColorKoObjs,
  EDGE_COLOR_OBJECTS: leatherColorKoObjs,
  STITCH_COLOR_OBJECTS: stitchColorKoObjs,
  HAMIDASHI_COLOR_OBJECTS: hamidashiKoObjs,
  HAMIDASHI_COLOR_ALL_OBJECTS: hamidashiAllKoObjs,
  HAMIDASHI_COLOR_FOR_CATCHER_OBJECTS: hamidashiForCatcherKoObjs,
  MOUTON_COLOR_OBJECTS: listLiningsMaterialKoObjs,
  LABEL_COLOR_OBJECTS: atomsLabelKoObjs,
  ERM_LABEL_COLOR_OBJECTS: atomsERMLabelKoObjs,
  SELECT_BACK_STYLE_DESCRIBE: '백스타일을 먼저 선택해주세요.',
  LEATHER_COLOR_OBJECTS_BY_TANNER: leatherColorKoObjesByTanner,
  // dispatchここから
  DISPATCH_COLOR_OBJECTS: dispatchKoObjects,
  DISPATCH_EUROPEAN_LEATHER_COLOR_OBJECTS: dispatchKoObjects, // must be changed!!!!!
  DISPATCH_NORTH_LEATHER_COLOR_OBJECTS: dispatchKoObjects, // must be changed!!!!!
  DISPATCH_STEER_LEATHER_COLOR_OBJECTS: dispatchKoObjects, // must be changed!!!!!
  DISPATCH_US_LEATHER_COLOR_OBJECTS: dispatchKoObjects, // must be deleted!!!!!
  // dispatchここまで
  CATCH_FACE_OBJECTS: catchFacePartsKoObjs,
  CATCH_FACE_FOR_CATCHER_OBJECTS: catcherMittCatchFaceKoObjs,

  // 背面パーツここから
  FIRST_BACK_OBJECTS: gloveFirstBackPartsKoObjs,
  CROWN_BACK_OBJECTS: gloveCrownBackPartsKoObjs,
  BELT_BACK_OBJECTS: gloveBeltBackPartsKoObjs,
  US_MODEL_BACK_OBJECTS: gloveUsBackPartsKoObjs,
  UBACK_OBJECTS: infielderBackPartsForUBackKoObjs,
  NO_BELT_BACK_OBJECTS: gloveBeltBackPartsNoBeltKoObjs,
  C_MITT_REGULAR_BACK_OBJECTS: catcherMittBackofRegularBackStylePartsKoObjs,
  C_MITT_BELT_BACK_OBJECTS: catcherMittBackofBeltBackStylePartsKoObjs,
  C_MITT_BACK_OBJECTS: [], //韓国版はミット系未実装
  F_MITT_NO_BELT_BACK_OBJECTS: [],
  F_MITT_BACK_OBJECTS: [],
  EMBROIDERY_POSITION_OBJECTS: positionKoObjs,
  EMBROIDERY_TYPEFACE_OBJECTS: typeFaceKoObjs,
  EMBROIDERY_COLOR_OBJECTS: colorKoObjs,
  EMBROIDERY_SHADOW_EDGE_COLOR_OBJECTS: shadowEdgeColorKoObjs,
  CAUTION_EMBROIDERY_POSITION: '자수위치를 변경하려면 자수내용을 리셋해주세요.',
  CAUTION_EMBROIDERY_TYPEFACE: '글씨체는 실제와 상이 할 수 있습니다. 미리 양해 부탁 드립니다.',
  CAUTION_EMBROIDERY_CONTENTS: '"자수 위치"를 선택하십시오.',
  CAUTION_EMBROIDERY_SHADOW_EDGE_COLOR: '"자수 컬러"를 선택해주세요.',
  DESCRIBE_EMBROIDERY_PLUS_BUTTON: `2군데 자수를 원하시면 "+" 버튼을 누르신 후 자수2를 표시하여 입력해 주세요.`,
  DESCRIBE_EMBROIDERY_MINUS_BUTTON: `한 곳을 삭제하고자 하는 경우에는 '-'를 클릭하십시오.`,
  NO_SELECTION: '선택 불가',
  INITIAL_STATE_C_MITT: initialStateCatcherMittKo,
  INITIAL_STATE_F_MITT: initialStateFirstMittKo,
  INITIAL_STATE_INFIELDER: initialInfielderStateKo,
  INITIAL_STATE_OUTFIELDER: initialOutfielderStateKo,
  INITIAL_STATE_PITCHER: initialPitcherStateKo,
  INITIAL_STATE_US_GLOVE: initialUsGloveStateKo,
  INITIAL_STATE_YOUTH: initialYouthState, // 韓国版は無いため、jaのobjectを流用
  TO_OTHER_LANGUAGES_PAGE: 'To English Page →',
  SENDING_MAIL: '송신중...',
  SEND_MAIL_SUCCESSFULL: '송신 완료',
  LEAGUE_OPTIONS: leagueKoOptions,
  POSITION_OPTIONS: positionKoOptions,
  BASE_PRICE_GLOVE: 71500,
  BASE_PRICE_MITT: 71500,
  MAIN_COLOR_PRICE: 2420, // 税込
  SHADOW_COLOR_PRICE: 2970, // 税込
  EDGE_COLOR_PRICE: 4620, // 税込
  AHN_JAE_SEOK_MODEL: {
    description: ' 옆으로 살짝 얕은 패턴으로 제작되어 힐 부분이 견고하고 넓어볼집을좀더넓게사용할 수 있는 타입입니다.',
    size: 11.75,
    productNumber: '안재석 선수'
  },
  SHIN_JUNG_WOO_MODEL: {
    description:
      '옆으로 깊은 패턴으로 잘 잡아줄 뿐만 아니라 밴드 부분이 얇은 ERM-Wrist로 제작되어 손목 가동 범위를 넓게 하여 다양한 각도의 타구도 확실하게 잡아주는 패턴입니다.',
    size: 11.75,
    productNumber: '신준우 선수'
  },
  PARK_GE_BUM_MODEL: {
    description: '내야수 글러브 중 유일하게 [레귤러 감기]로 제작하여 입수부의 와이드함을 경감 시킨 패턴입니다.',
    size: 12,
    productNumber: '박계범 선수'
  },
  HAFN_DONG_HEE_MODEL: {
    description: '힐 부분이 넓고 유격수, 3루수용에 적합하며 난이도 높은 타구도 확실하게 잡아주는 패턴입니다.',
    size: 12,
    productNumber: '한동희 선수'
  },
  LANGUAGE: '언어',
  WEB2_PARTS_OBJECT: { label: '웹2', value: 'web2', order: 3 },
  EMBROIDERY_CONTENT_EMOJI_CAUTION: '이모티콘, 기호는 ⭐︎,🩷 만 자수 가능합니다.'
}
