export const tannerObjs = [
  { label: 'European Steer Leather', value: 'europeanSteer' },
  { label: 'North Steer Leather', value: 'northSteer' },
  { label: 'Steer Leather', value: 'steer' }
]
export const tannerEnObjs = [{ label: 'US Leather', value: 'usSteer' }]
export const leatherColorEnObjesByTanner = {
  northSteer: [
    { label: 'Cork', value: 'cork', color: '#CE7220' },
    { label: 'Black', value: 'black', color: '#1E1E1E' },
    { label: 'Orange', value: 'orange', color: '#FF6C23' },
    { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' },
    { label: 'Red', value: 'red', color: '#CB1527' },
    { label: 'Teal Green', value: 'tealGreen', color: '#317290' },
    { label: 'Navy', value: 'navy', color: '#0d194a' }
  ]
  // ,europeanSteer: [
  //   { label: 'K Cork', value: 'kCork', color: '#CE7220' },
  //   { label: 'K Black', value: 'kBlack', color: '#1E1E1E' },
  //   { label: 'K Red Orange', value: 'kRedOrange', color: '#FF362E' },
  //   { label: 'K Orange', value: 'kOrange', color: '#FF6C23' },
  //   { label: 'K CBrown', value: 'kCamelBrown', color: '#D7CEA5' }
  // ],
  // steer: [
  //   { label: 'Cork', value: 'Cork', color: '#CE7220' },
  //   { label: 'Black', value: 'black', color: '#1E1E1E' },
  //   { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  //   { label: 'Orange', value: 'orange', color: '#FF6C23' },
  //   { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' }
  // ]
}

const EUROPEAN_STEER_LEATHER_COLORS_EN = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'C Brown', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'D Brown', value: 'dBrown', color: '#350816' },
  { label: 'Chocolate', value: 'chocolate', color: '#6b3b36' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#D9D943' }
]

const premiumLeatherColorObjsEn = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' }
]
const southLeatherColorObjsEn = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Yellow', value: 'yellow', color: '#E8C84B' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#D9D943' },
  { label: 'Chocolate', value: 'chocolate', color: '#6b3b36' },
  { label: 'Red', value: 'red', color: '#CB1527' },
  { label: 'Navy', value: 'navy', color: '#0d194a' },
  { label: 'Blue', value: 'blue', color: '#0d42b5' },
  { label: 'Valor Blue', value: 'valorBlue', color: '#61BCBA' },
  { label: 'Mardi Gras', value: 'mardiGras', color: '#4e734c' },
  { label: 'Teal', value: 'teal', color: '#317290' },
  { label: 'Purple', value: 'purple', color: '#664785' },
  { label: 'Pink', value: 'pink', color: '#F94C97' },
  { label: 'White', value: 'white', color: '#fff' },
  { label: 'Gray', value: 'gray', color: '#8A8C8C' }
]
const northLeatherColorObjsEn = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Red', value: 'red', color: '#CB1527' },
  { label: 'Teal Green', value: 'tealGreen', color: '#317290' },
  { label: 'Navy', value: 'navy', color: '#0d194a' }
]
const nineStatesLeatherColorObjsEn = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Yellow', value: 'yellow', color: '#E8C84B' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#D9D943' },
  { label: 'Chocolate', value: 'chocolate', color: '#6b3b36' },
  { label: 'Red', value: 'red', color: '#CB1527' },
  { label: 'Navy', value: 'navy', color: '#0d194a' },
  { label: 'Blue', value: 'blue', color: '#0d42b5' },
  { label: 'Valor Blue', value: 'valorBlue', color: '#61BCBA' },
  { label: 'Mardi Gras', value: 'mardiGras', color: '#4e734c' },
  { label: 'Teal', value: 'teal', color: '#317290' },
  { label: 'Purple', value: 'purple', color: '#664785' },
  { label: 'Pink', value: 'pink', color: '#F94C97' },
  { label: 'White', value: 'white', color: '#fff' },
  { label: 'Gray', value: 'gray', color: '#8A8C8C' }
]
export const leatherColorObjsEn = [
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Black', value: 'black', color: '#1E1E1E' },
  { label: 'CBrown', value: 'camelBrown', color: '#D7CEA5' },
  { label: 'Yellow', value: 'yellow', color: '#E8C84B' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#D9D943' },
  { label: 'Chocolate', value: 'chocolate', color: '#6b3b36' },
  { label: 'Red', value: 'red', color: '#CB1527' },
  { label: 'Navy', value: 'navy', color: '#0d194a' },
  { label: 'Blue', value: 'blue', color: '#0d42b5' },
  { label: 'Valor Blue', value: 'valorBlue', color: '#61BCBA' },
  { label: 'Mardi Gras', value: 'mardiGras', color: '#4e734c' },
  { label: 'Teal', value: 'teal', color: '#317290' },
  { label: 'Purple', value: 'purple', color: '#664785' },
  { label: 'Pink', value: 'pink', color: '#F94C97' },
  { label: 'White', value: 'white', color: '#fff' },
  { label: 'Gray', value: 'gray', color: '#8A8C8C' }
]
export const liningColorEnObjs = [...leatherColorObjsEn, { label: 'Deer Black', value: 'deerBlack', color: '#1E1E1E' }]
export const laceColorEnObjs = [
  // { label: 'USA Tang', value: 'tang', color: '#82573C' },
  { label: 'Gray', value: 'gray', color: '#8A8C8C' },
  { label: 'White', value: 'white', color: '#fff' },
  { label: 'Black with inside white', value: 'materialBlack', color: '#282925' },
  { label: 'Black', value: 'black', color: '#282925' },
  { label: 'Red Orange with inside white', value: 'materialRedOrange', color: '#FF362E' },
  { label: 'Red Orange', value: 'redOrange', color: '#FF362E' },
  { label: 'Orange with inside white', value: 'materialOrange', color: '#FF6C23' },
  { label: 'Orange', value: 'orange', color: '#FF6C23' },
  { label: 'Yellow', value: 'yellow', color: '#E8C84B' },
  { label: 'Lemon Yellow', value: 'lemonYellow', color: '#D9D943' },
  { label: 'Cork', value: 'Cork', color: '#CE7220' },
  { label: 'Chocolate', value: 'chocolate', color: '#70352e' },
  { label: 'Red', value: 'red', color: '#CB1527' },
  { label: 'Navy', value: 'navy', color: '#202742' },
  { label: 'Blue', value: 'blue', color: '#193B85' },
  { label: 'CBrown', value: 'brown', color: '#D7CEA5' }
]
export const stitchColorEnObjs = [
  { label: 'Black', value: 'black', color: '#0a0a0a' },
  { label: 'Orange', value: 'orange', color: '#e37d00' },
  { label: 'Yellow', value: 'yellow', color: '#debd00' },
  { label: 'Cork', value: 'cork', color: '#CE7220' },
  { label: 'Pink', value: 'pink', color: '#ff1493' },
  { label: 'Sky Blue', value: 'skyBlue', color: '#4EB5DC' },
  { label: 'Red', value: 'red', color: '#c70000' },
  { label: 'Navy', value: 'navy', color: '#363C53' },
  { label: 'Blue', value: 'blue', color: '#002bff' },
  { label: 'White', value: 'white', color: '#eee' },
  { label: 'Green', value: 'green', color: '#009c09' },
  { label: 'Purple', value: 'purple', color: '#6a1ca3' }
]
export const hamidashiEnObjs = [
  { label: 'Flat (White)', value: 'kirihamiWhite', color: '#fff' },
  { label: 'Roll (Yellow)', value: 'yellow', color: '#E8C84B' },
  { label: 'Roll (Lemon Yellow)', value: 'lemonYellow', color: '#D9D943' },
  { label: 'Roll (Cork)', value: 'cork', color: '#CE7220' },
  { label: 'Roll (Chocolate)', value: 'chocolate', color: '#70352e' },
  { label: 'Roll (Red)', value: 'red', color: '#CB1527' },
  { label: 'Roll (Navy)', value: 'navy', color: '#202742' },
  { label: 'Roll (Blue)', value: 'blue', color: '#193B85' },
  { label: 'Roll (Black)', value: 'black', color: '#1E1E1E' },
  { label: 'Roll (Orange)', value: 'orange', color: '#FF6C23' },
  { label: 'Roll (Red Orange)', value: 'redOrange', color: '#FF362E' },
  { label: 'Roll (Valor Blue)', value: 'valorBlue', color: '#61BCBA' },
  { label: 'Roll (Mardi Gras)', value: 'mardiGras', color: '#4e734c' },
  { label: 'Roll (Teal)', value: 'teal', color: '#317290' },
  { label: 'Roll (Purple)', value: 'purple', color: '#664785' },
  { label: 'Roll (Pink)', value: 'pink', color: '#F94C97' },
  { label: 'Roll (White)', value: 'white', color: '#fff' },
  { label: 'Roll (Gray)', value: 'gray', color: '#8A8C8C' },
  { label: 'Roll (CBrown)', value: 'brown', color: '#D7CEA5' }
]
export const hamidashiAllEnObjs = [{ label: 'No welting', value: 'none', color: '#fff' }, ...hamidashiEnObjs]
export const hamidashiForCatcherEnObjs = hamidashiAllEnObjs
export const listLiningsMaterialEnObjs = [
  { label: 'White', value: 'moutonWhite', color: '#fff', imageUrl: '/atoms/colorSettings/mouton/white.jpg' },
  { label: 'Black', value: 'moutonBlack', color: '#1a1a1a', imageUrl: '/atoms/colorSettings/mouton/black.jpg' }
]
const commonPartsEnObjs = [
  { label: 'Over all', value: 'all', order: 0 },
  { label: 'Palm', value: 'catchFace', order: 1 },
  { label: 'Web', value: 'web', order: 2 },
  { label: 'Binding', value: 'edge', order: 4 },
  { label: 'Thumb felt loops', value: 'thumbHook', order: 5 },
  { label: 'Pinky felt loops', value: 'littleHook', order: 6 },
  { label: 'Finger Guard', value: 'indexCover', order: 7 },
  { label: 'Liner', value: 'linings', order: 8 }
]
const backBeltStyleGloveNoBeltEnObjs = [
  { label: 'Thumb1', value: 'thumbOut', order: 9 },
  { label: 'Thumb2', value: 'thumbWeb', order: 10 },
  { label: 'Index3', value: 'indexWeb', order: 11 },
  { label: 'Index4', value: 'indexMiddle', order: 12 },
  { label: 'Middle5', value: 'middleIndex', order: 13 },
  { label: 'Middle6', value: 'middleRing', order: 14 },
  { label: 'Ring7', value: 'ringMiddle', order: 15 },
  { label: 'Ring8', value: 'ringLittle', order: 16 },
  { label: 'Pinky9', value: 'littleRing', order: 17 },
  { label: 'Pinky10', value: 'littleOut', order: 18 }
]
const backBeltStyleGloveEnObjs = [...backBeltStyleGloveNoBeltEnObjs, { label: 'Band', value: 'listBelt', order: 19 }]
const backFirstBackGloveEnObjs = [
  { label: 'Thumb1', value: 'thumbOut', order: 9 },
  { label: 'Thumb2 / Index / Middle5', value: 'thumbIndexMiddle', order: 10 },
  { label: 'Middle6 / Ring7', value: 'middleLeftRingRight', order: 11 },
  { label: 'Ring8 / Pinky9', value: 'ringLeftLittleRight', order: 12 },
  { label: 'Pinky10', value: 'littleOut', order: 13 },
  { label: 'Belt', value: 'listBelt', order: 14 }
]
const backCrownBackGloveEnObjs = [
  { label: 'Thumb1', value: 'thumbOut', order: 9 },
  { label: 'Thumb2', value: 'thumbWeb', order: 10 },
  { label: 'Index3', value: 'indexWeb', order: 11 },
  { label: 'Index4 / Middle5', value: 'indexLeftMiddleRight', order: 12 },
  { label: 'Middle6 / Ring7', value: 'middleLeftRingRight', order: 13 },
  { label: 'Ring8 / Pinky9', value: 'ringLeftLittleRight', order: 14 },
  { label: 'Pinky10', value: 'littleOut', order: 15 },
  { label: 'Band', value: 'listBelt', order: 16 }
]
const backGloveUsBackEnObjs = [
  { label: 'Thumb1', value: 'thumbOut', order: 9 },
  { label: 'Thumb2', value: 'thumbWeb', order: 10 },
  { label: 'Thumb root', value: 'thumbRoot', order: 11 },
  { label: 'Index3', value: 'indexWeb', order: 12 },
  { label: 'Index4', value: 'indexMiddle', order: 13 },
  { label: 'Middle5', value: 'middleIndex', order: 14 },
  { label: 'Middle6', value: 'middleRing', order: 15 },
  { label: 'Ring7', value: 'ringMiddle', order: 16 },
  { label: 'Ring8', value: 'ringLittle', order: 17 },
  { label: 'Pinky9', value: 'littleRing', order: 18 },
  { label: 'Pinky10', value: 'littleOut', order: 19 }
]
const backGloveForUBackEnObjs = [
  { label: 'Thumb1', value: 'thumbOut', order: 9 },
  { label: 'Thumb2・Index3', value: 'thumbWebAndIndexWeb', order: 10 },
  { label: 'Index4', value: 'indexMiddle', order: 11 },
  { label: 'Middle5', value: 'middleIndex', order: 12 },
  { label: 'Middle6', value: 'middleRing', order: 13 },
  { label: 'Ring7', value: 'ringMiddle', order: 14 },
  { label: 'Ring8', value: 'ringLittle', order: 15 },
  { label: 'Pinky9', value: 'littleRing', order: 16 },
  { label: 'Pinky10', value: 'littleOut', order: 17 },
  { label: 'Band', value: 'listBelt', order: 18 }
]
const backOfRegularBackStyleCatcherMittEnObjs = [
  { label: 'Thumb・Index・Middle5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: 'Middle6・Ring', value: 'middleLeftRing', order: 10 },
  { label: 'Finger Tip Out', value: 'indexFingerTipOut', order: 11 },
  { label: 'Pinky10', value: 'littleOut', order: 12 },
  { label: 'Stand', value: 'fingerStand', order: 13 },
  { label: 'Band', value: 'listBelt', order: 14 }
]
const backOfBeltBackStyleCatcherMittEnObjs = [
  { label: 'Thumb・Index・Middle5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: 'Middle6・Ring・Pinky', value: 'middleLeftRingLittle', order: 10 },
  { label: 'Stand', value: 'fingerStand', order: 11 }
]
const backFirstMittEnObjs = [
  { label: 'Band', value: 'listBelt', order: 9 },
  { label: 'Thumb', value: 'thumb', order: 10 },
  { label: 'Under web', value: 'underWeb', order: 11 },
  { label: 'Boomerang', value: 'boomerang', order: 12 }
]
const backFirstMittNoBeltEnObjs = [
  { label: 'Thumb', value: 'thumb', order: 9 },
  { label: 'Under web', value: 'underWeb', order: 10 },
  { label: 'Boomerang', value: 'boomerang', order: 11 }
]
export const catcherMittBackPartsEnObjs = [
  { label: 'Thumb / Index / Middle5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: 'Middle6 / Ring / Pinky', value: 'middleLeftRingLittle', order: 10 },
  { label: 'Middle6 / Ring', value: 'middleLeftRing', order: 11 },
  { label: 'Pinky10', value: 'littleOut', order: 12 },
  { label: 'Stand', value: 'fingerStand', order: 13 }
]

// 捕球面
export const catchFacePartsEnObjs = commonPartsEnObjs
export const catcherMittCatchFaceEnObjs = [...commonPartsEnObjs, { label: 'Target', value: 'catchFaceTarget', order: 9 }]
// 背面
export const gloveFirstBackPartsEnObjs = [...commonPartsEnObjs, ...backFirstBackGloveEnObjs]
export const gloveUsBackPartsEnObjs = [...commonPartsEnObjs, ...backGloveUsBackEnObjs]
export const gloveCrownBackPartsEnObjs = [...commonPartsEnObjs, ...backCrownBackGloveEnObjs]
export const gloveBeltBackPartsEnObjs = [...commonPartsEnObjs, ...backBeltStyleGloveEnObjs]
export const uBackEnObjs = [...commonPartsEnObjs, ...backGloveForUBackEnObjs]
export const gloveBeltBackPartsNoBeltEnObjs = [...commonPartsEnObjs, ...backBeltStyleGloveNoBeltEnObjs]
export const catcherMittBackofRegularBackStylePartsEnObjs = [...commonPartsEnObjs, ...backOfRegularBackStyleCatcherMittEnObjs]
export const catcherMittBackofBeltBackStylePartsEnObjs = [...commonPartsEnObjs, ...backOfBeltBackStyleCatcherMittEnObjs]
export const firstMittBackPartsNoBeltEnObjs = [...commonPartsEnObjs, ...backFirstMittNoBeltEnObjs]
export const firstMittBackPartsEnObjs = [...commonPartsEnObjs, ...backFirstMittEnObjs]
export const atomsLabelEnObjs = [
  { label: 'Black x Gold x Gold', value: 'ccc', imageUrl: '/atoms/labels/c.jpg' },
  { label: 'Black x Gold x Champagne Gold', value: 'ddd', imageUrl: '/atoms/labels/d.jpg' },
  { label: 'Navy x Red x Gold', value: 'eee', imageUrl: '/atoms/labels/e.jpg' },
  { label: 'Red x Navy x Gold', value: 'fff', imageUrl: '/atoms/labels/f.jpg' },
  { label: 'Black x Gold x Silver', value: 'ggg', imageUrl: '/atoms/labels/g.jpg' },
  { label: 'Blue x Red x Gold', value: 'hhh', imageUrl: '/atoms/labels/h.jpg' },
  { label: 'Black x Gold x Blue', value: 'iii', imageUrl: '/atoms/labels/i.jpg' },
  { label: 'Black x Platinum Gold x Gold', value: 'jjj', imageUrl: '/atoms/labels/j.jpg' },
  { label: 'Black x Black x Black', value: 'kkk', imageUrl: '/atoms/labels/k.jpg' },
  { label: 'Black x Silicon Gold', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: 'Black x Silicon Platinum', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: 'Black x Silicon Black Lame', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: 'Black x Gold x Pink', value: 'uuu', imageUrl: '/atoms/labels/u.jpg' },
  { label: 'Black x Light Gold x Light Gold', value: 'yyy', imageUrl: '/atoms/labels/y.jpg' },
  { label: 'Black x Silicon Silver', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const atomsERMLabelObjs = [
  { label: '黒xシリコンゴールド', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '黒xシリコンプラチナ', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '黒xシリコンブラックラメ', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '黒xシリコンシルバー', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const atomsERMLabelEnObjs = [
  { label: 'Black x Silicon Gold', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: 'Black x Silicon Platinum', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: 'Black x Silicon Black Lame', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: 'Black x Silicon Silver', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const moutonObjs = [
  { label: '白', value: 'white', color: '#fff' },
  { label: '黒', value: 'black', color: '#1E1E1E' }
]
export const dispatchEuropeanSteerEn = {
  linings: EUROPEAN_STEER_LEATHER_COLORS_EN,
  all: EUROPEAN_STEER_LEATHER_COLORS_EN,
  catchFace: EUROPEAN_STEER_LEATHER_COLORS_EN,
  web: EUROPEAN_STEER_LEATHER_COLORS_EN,
  web2: EUROPEAN_STEER_LEATHER_COLORS_EN,
  edge: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbHook: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbWebAndIndexWeb: EUROPEAN_STEER_LEATHER_COLORS_EN,
  littleHook: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexCover: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbOut: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbWeb: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbRoot: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbIndexMiddle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumbIndexMiddleRight: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexWeb: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexMiddle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  middleIndex: EUROPEAN_STEER_LEATHER_COLORS_EN,
  middleRing: EUROPEAN_STEER_LEATHER_COLORS_EN,
  middleLeftRing: EUROPEAN_STEER_LEATHER_COLORS_EN,
  middleLeftRingRight: EUROPEAN_STEER_LEATHER_COLORS_EN,
  middleLeftRingLittle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  ringMiddle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  ringLittle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  ringLeftLittleRight: EUROPEAN_STEER_LEATHER_COLORS_EN,
  littleRing: EUROPEAN_STEER_LEATHER_COLORS_EN,
  littleOut: EUROPEAN_STEER_LEATHER_COLORS_EN,
  listBelt: EUROPEAN_STEER_LEATHER_COLORS_EN,
  thumb: EUROPEAN_STEER_LEATHER_COLORS_EN,
  boomerang: EUROPEAN_STEER_LEATHER_COLORS_EN,
  underWeb: EUROPEAN_STEER_LEATHER_COLORS_EN,
  catchFaceTarget: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexLeftMiddleRight: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexAndMiddle: EUROPEAN_STEER_LEATHER_COLORS_EN,
  fingerStand: EUROPEAN_STEER_LEATHER_COLORS_EN,
  indexFingerTipOut: EUROPEAN_STEER_LEATHER_COLORS_EN
}

export const dispatchNorthSteerObjectsEn = {}
export const dispatchEnObjects = {
  linings: liningColorEnObjs,
  listLiningsMaterial: listLiningsMaterialEnObjs,
  all: leatherColorObjsEn,
  catchFace: leatherColorObjsEn,
  web: leatherColorObjsEn,
  web2: leatherColorObjsEn,
  edge: leatherColorObjsEn,
  thumbHook: leatherColorObjsEn,
  thumbWebAndIndexWeb: leatherColorObjsEn,
  littleHook: leatherColorObjsEn,
  indexCover: leatherColorObjsEn,
  thumbOut: leatherColorObjsEn,
  thumbWeb: leatherColorObjsEn,
  thumbRoot: leatherColorObjsEn,
  thumbIndexMiddle: leatherColorObjsEn,
  thumbIndexMiddleRight: leatherColorObjsEn,
  indexWeb: leatherColorObjsEn,
  indexMiddle: leatherColorObjsEn,
  middleIndex: leatherColorObjsEn,
  middleRing: leatherColorObjsEn,
  middleLeftRing: leatherColorObjsEn,
  middleLeftRingRight: leatherColorObjsEn,
  middleLeftRingLittle: leatherColorObjsEn,
  ringMiddle: leatherColorObjsEn,
  ringLittle: leatherColorObjsEn,
  ringLeftLittleRight: leatherColorObjsEn,
  littleRing: leatherColorObjsEn,
  littleOut: leatherColorObjsEn,
  listBelt: leatherColorObjsEn,
  thumb: leatherColorObjsEn,
  boomerang: leatherColorObjsEn,
  underWeb: leatherColorObjsEn,
  catchFaceTarget: leatherColorObjsEn,
  indexLeftMiddleRight: leatherColorObjsEn,
  indexAndMiddle: leatherColorObjsEn,
  fingerStand: leatherColorObjsEn,
  indexFingerTipOut: leatherColorObjsEn
}
export const en2JaColorPartsTranslates = [
  { en: 'South Steer Leather', japanese: 'South Steerレザー' },
  { en: 'Cork', japanese: 'コルク' },
  { en: 'Black', japanese: 'ブラック' },
  { en: 'Red Orange', japanese: 'レッドオレンジ' },
  { en: 'Orange', japanese: 'オレンジ' },
  { en: 'CBrown', japanese: 'Cブラウン' },
  { en: 'C Brown', japanese: 'C ブラウン' },
  { en: 'D Brown', japanese: 'D ブラウン' },
  { en: 'Yellow', japanese: 'イエロー' },
  { en: 'Lemon Yellow', japanese: 'レモンイエロー' },
  { en: 'Chocolate', japanese: 'チョコレート' },
  { en: 'Red', japanese: 'レッド' },
  { en: 'Navy', japanese: 'ネイビー' },
  { en: 'Blue', japanese: 'ブルー' },
  { en: 'Valor Blue', japanese: 'ベイラーブルー' },
  { en: 'Mardi Gras', japanese: 'マディグラス' },
  { en: 'Teal', japanese: 'ティール' },
  { en: 'Teal Green', japanese: 'ティール' },
  { en: 'Purple', japanese: 'パープル' },
  { en: 'Pink', japanese: 'ピンク' },
  { en: 'White', japanese: 'ホワイト' },
  { en: 'Gray', japanese: 'グレー' },
  { en: 'Black with inside white', japanese: '中白ブラック' },
  { en: 'Red Orange with inside white', japanese: '中白レッドオレンジ' },
  { en: 'Orange with inside white', japanese: '中白オレンジ' },
  { en: 'Sky Blue', japanese: 'スカイブルー' },
  { en: 'Green', japanese: 'グリーン' },
  { en: 'Flat (White)', japanese: '切りハミ(ホワイト)' },
  { en: 'Roll (Yellow)', japanese: '玉ハミ(イエロー)' },
  { en: 'Roll (Lemon Yellow)', japanese: '玉ハミ(レモンイエロー)' },
  { en: 'Roll (Cork)', japanese: '玉ハミ(コルク)' },
  { en: 'Roll (Chocolate)', japanese: '玉ハミ(チョコレート)' },
  { en: 'Roll (Red)', japanese: '玉ハミ(レッド)' },
  { en: 'Roll (Navy)', japanese: '玉ハミ(ネイビー)' },
  { en: 'Roll (Blue)', japanese: '玉ハミ(ブルー)' },
  { en: 'Roll (Black)', japanese: '玉ハミ(ブラック)' },
  { en: 'Roll (Orange)', japanese: '玉ハミ(オレンジ)' },
  { en: 'Roll (Red Orange)', japanese: '玉ハミ(レッドオレンジ)' },
  { en: 'Roll (Valor Blue)', japanese: '玉ハミ(ベイラーブルー)' },
  { en: 'Roll (Mardi Gras)', japanese: '玉ハミ(マディグラス)' },
  { en: 'Roll (Teal)', japanese: '玉ハミ(ティール)' },
  { en: 'Roll (Purple)', japanese: '玉ハミ(パープル)' },
  { en: 'Roll (Pink)', japanese: '玉ハミ(ピンク)' },
  { en: 'Roll (White)', japanese: '玉ハミ(ホワイト)' },
  { en: 'Roll (Gray)', japanese: '玉ハミ(グレー)' },
  { en: 'Roll (CBrown)', japanese: '玉ハミ(Cブラウン)' },
  { en: 'No welting', japanese: 'ハミダシなし(ツマミ)' },
  { en: 'Over all', japanese: '全体' },
  { en: 'Palm', japanese: '捕球面' },
  { en: 'Web', japanese: 'ウェブ' },
  { en: 'Binding', japanese: 'ヘリ革' },
  { en: 'Thumb felt loops', japanese: '親指掛け紐' },
  { en: 'Pinky felt loops', japanese: '小指掛け紐' },
  { en: 'Finger Guard', japanese: '指カバー/指当て' },
  { en: 'Liner', japanese: '裏革' },
  { en: 'Thumb1', japanese: '親指1' },
  { en: 'Thumb2', japanese: '親指2' },
  { en: 'Index3', japanese: '人差指3' },
  { en: 'Index4', japanese: '人差指4' },
  { en: 'Middle5', japanese: '中指5' },
  { en: 'Middle6', japanese: '中指6' },
  { en: 'Ring7', japanese: '薬指7' },
  { en: 'Ring8', japanese: '薬指8' },
  { en: 'Pinky9', japanese: '小指9' },
  { en: 'Pinky10', japanese: '小指10' },
  { en: 'Band', japanese: 'バンド' },
  { en: 'Thumb2 / Index / Middle5', japanese: '親指2・人差指・中指5' },
  { en: 'Middle6 / Ring7', japanese: '中指6・薬指7' },
  { en: 'Ring8 / Pinky9', japanese: '薬指8・小指9' },
  { en: 'Belt', japanese: 'ベルト' },
  { en: 'Index4 / Middle5', japanese: '人差指4・中指5' },
  { en: 'Thumb root', japanese: '親指根本' },
  { en: 'Thumb2・Index3', japanese: '親指2・人差指3' },
  { en: 'Thumb・Index・Middle5', japanese: '親指・人差指・中指5' },
  { en: 'Middle6・Ring', japanese: '中指6・薬指' },
  { en: 'Finger Tip Out', japanese: '指出し' },
  { en: 'Stand', japanese: '土台' },
  { en: 'Middle6・Ring・Pinky', japanese: '中指6・薬指・小指' },
  { en: 'Thumb', japanese: '親指' },
  { en: 'Under web', japanese: 'ウェブ下' },
  { en: 'Boomerang', japanese: 'ブーメラン' },
  { en: 'Target', japanese: 'ターゲット' },
  { en: 'Black x Gold x Gold', japanese: '黒xゴールドxゴールド' },
  { en: 'Black x Gold x Champagne Gold', japanese: '黒xゴールドxシャンパンゴールド' },
  { en: 'Navy x Red x Gold', japanese: 'ネイビーx赤xゴールド' },
  { en: 'Red x Navy x Gold', japanese: '赤xネイビーxゴールド' },
  { en: 'Black x Gold x Silver', japanese: '黒xゴールドxシルバー' },
  { en: 'Blue x Red x Gold', japanese: 'ブルーx赤xゴールド' },
  { en: 'Black x Gold x Blue', japanese: '黒xゴールドxブルー' },
  { en: 'Black x Platinum Gold x Gold', japanese: '黒xプラチナゴールドxゴールド' },
  { en: 'Black x Black x Black', japanese: '黒x黒x黒' },
  { en: 'Black x Silicon Gold', japanese: '黒xシリコンゴールド' },
  { en: 'Black x Silicon Platinum', japanese: '黒xシリコンプラチナ' },
  { en: 'Black x Silicon Black Lame', japanese: '黒xシリコンブラックラメ' },
  { en: 'Black x Gold x Pink', japanese: '黒xゴールドxピンク' },
  { en: 'Black x Light Gold x Light Gold', japanese: '黒xライトゴールドxライトゴールド' },
  { en: 'Black x Silicon Silver', japanese: '黒xシリコンシルバー' },
  { en: 'Cut (White)', japanese: '切りハミ(ホワイト)' }, // old
  { en: 'UC Brown', japanese: 'U Cブラウン' }, // old
  { en: 'Blue x Redx Gold', japanese: 'ブルーxレッドxゴールド' } // old
]
