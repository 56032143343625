export const tannerKoObjs = [
  // { label: '우라가미 가죽', value: 'urakami' }
  // { label: 'Juteruレザー', value: 'juteru' },
  // { label: 'K 가죽', value: 'k' },
  // { label: '테라다 가죽', value: 'terada' }
  // { label: 'North Steer Leather', value: 'northSteer' }
  { label: 'US Leather', value: 'usSteer' }
]

export const leatherColorKoObjesByTanner = {
  northSteer: [
    { label: '코르크', value: 'cork', color: '#CE7220' },
    { label: '블랙', value: 'black', color: '#1D1D1D' },
    { label: '오렌지', value: 'orange', color: '#FF6C23' },
    { label: 'C브라운', value: 'camelBrown', color: '#D7CEA5' },
    { label: '레드', value: 'red', color: '#CB1527' },
    { label: '네이비', value: 'navy', color: '#0d194a' },
    { label: '틸', value: 'tealGreen', color: '#317290' }
  ]
}

const nineStatesLeatherColorObjsKo = [
  { label: '코르크', value: 'cork', color: '#CE7220' },
  { label: '오렌지', value: 'orange', color: '#FF6C23' },
  { label: '레드오렌지', value: 'redOrange', color: '#FF362E' },
  { label: '블랙', value: 'black', color: '#1D1D1D' },
  { label: 'C브라운', value: 'camelBrown', color: '#D7CEA5' },
  { label: '옐로우', value: 'yellow', color: '#E8C84B' },
  { label: '레몬 옐로우', value: 'lemonYellow', color: '#D9D943' },
  { label: '초콜릿', value: 'chocolate', color: '#6b3b36' },
  { label: '레드', value: 'red', color: '#CB1527' },
  { label: '네이비', value: 'navy', color: '#0d194a' },
  { label: '블루', value: 'blue', color: '#0d42b5' },
  { label: '베일러 블루', value: 'valorBlue', color: '#61BCBA' },
  { label: '매디글라스', value: 'mardiGras', color: '#4e734c' },
  { label: '틸', value: 'tealGreen', color: '#317290' },
  { label: '퍼플', value: 'purple', color: '#664785' },
  { label: '핑크', value: 'pink', color: '#F94C97' },
  { label: '화이트', value: 'white', color: '#fff' },
  { label: '그레이', value: 'gray', color: '#8A8C8C' }
]

export const listLiningsMaterialKoObjs = [
  { label: '화이트', value: 'moutonWhite', color: '#fff', imageUrl: '' },
  { label: '블랙', value: 'moutonBlack', color: '#1a1a1a', imageUrl: '' }
]

export const dispatchNorthSteerKo = {}

export const leatherColorKoObjs = [
  { label: '옐로우', value: 'yellow', color: '#E8C84B' },
  { label: '레몬 옐로우', value: 'lemonYellow', color: '#D9D943' },
  { label: '초콜릿', value: 'chocolate', color: '#6b3b36' },
  { label: '레드', value: 'red', color: '#CB1527' },
  { label: '네이비', value: 'navy', color: '#0d194a' },
  { label: '블루', value: 'blue', color: '#0d42b5' },
  { label: '베일러 블루', value: 'valorBlue', color: '#61BCBA' },
  { label: '매디글라스', value: 'mardiGras', color: '#4e734c' },
  { label: '틸', value: 'tealGreen', color: '#317290' },
  { label: '퍼플', value: 'purple', color: '#664785' },
  { label: '핑크', value: 'pink', color: '#F94C97' },
  { label: '화이트', value: 'white', color: '#fff' },
  { label: '그레이', value: 'gray', color: '#8A8C8C' }
]

export const liningColorKoObjs = [...leatherColorKoObjs]

export const laceColorKoObjs = [
  // { label: 'USA탄', value: 'tang', color: '#82573C' },
  { label: '그레이', value: 'gray', color: '#8A8C8C' },
  { label: '화이트', value: 'white', color: '#fff' },
  { label: '블랙&화이트', value: 'materialBlack', color: '#1D1D1D' },
  { label: '블랙', value: 'black', color: '#1D1D1D' },
  { label: '레드&오렌지&화이트', value: 'materialRedOrange', color: '#FF362E' },
  { label: '레드오렌지', value: 'redOrange', color: '#FF362E' },
  { label: '오렌지&화이트', value: 'materialOrange', color: '#FF6C23' },
  { label: '오렌지', value: 'orange', color: '#FF6C23' },
  { label: '옐로우', value: 'yellow', color: '#E8C84B' },
  { label: '레몬 옐로우', value: 'lemonYellow', color: '#D9D943' },
  { label: '코르크', value: 'cork', color: '#CE7220' },
  { label: '초콜릿', value: 'chocolate', color: '#70352e' },
  { label: '레드', value: 'red', color: '#CB1527' },
  { label: '네이비', value: 'navy', color: '#202742' },
  { label: '블루', value: 'blue', color: '#193B85' },
  { label: 'C브라운', value: 'brown', color: '#D7CEA5' }
]

export const stitchColorKoObjs = [
  { label: '블랙', value: 'black', color: '#0a0a0a' },
  { label: '오렌지', value: 'orange', color: '#e37d00' },
  { label: '옐로우', value: 'yellow', color: '#debd00' },
  { label: '코르크', value: 'cork', color: '#CE7220' },
  { label: '핑크', value: 'pink', color: '#ff1493' },
  { label: '스카이 블루', value: 'skyBlue', color: '#4EB5DC' },
  { label: '레드', value: 'red', color: '#c70000' },
  { label: '네이비', value: 'navy', color: '#363C53' },
  { label: '블루', value: 'blue', color: '#002bff' },
  { label: '화이트', value: 'white', color: '#eee' },
  { label: '그린', value: 'green', color: '#009c09' },
  { label: '퍼플', value: 'purple', color: '#6a1ca3' }
]

export const hamidashiKoObjs = [
  { label: '컷(화이트)', value: 'kirihamiWhite', color: '#fff' },
  { label: '둥근 웰팅(옐로우)', value: 'yellow', color: '#E8C84B' },
  { label: '둥근 웰팅(레몬 옐로우)', value: 'lemonYellow', color: '#D9D943' },
  { label: '둥근 웰팅(코르크)', value: 'cork', color: '#CE7220' },
  { label: '둥근 웰팅(초콜릿)', value: 'chocolate', color: '#70352e' },
  { label: '둥근 웰팅(레드)', value: 'red', color: '#CB1527' },
  { label: '둥근 웰팅(네이비)', value: 'navy', color: '#202742' },
  { label: '둥근 웰팅(블루)', value: 'blue', color: '#193B85' },
  { label: '둥근 웰팅(블랙)', value: 'black', color: '#1D1D1D' },
  { label: '둥근 웰팅(오렌지)', value: 'orange', color: '#FF6C23' },
  { label: '둥근 웰팅(레드오렌지)', value: 'redOrange', color: '#FF362E' },
  { label: '둥근 웰팅(베일러 블루)', value: 'valorBlue', color: '#61BCBA' },
  { label: '둥근 웰팅(매디글라스)', value: 'mardiGras', color: '#4e734c' },
  { label: '둥근 웰팅(틸)', value: 'teal', color: '#317290' },
  { label: '둥근 웰팅(퍼플)', value: 'purple', color: '#664785' },
  { label: '둥근 웰팅(핑크)', value: 'pink', color: '#F94C97' },
  { label: '둥근 웰팅(화이트)', value: 'white', color: '#fff' },
  { label: '둥근 웰팅(그레이)', value: 'gray', color: '#8A8C8C' },
  { label: '둥근 웰팅(C브라운)', value: 'brown', color: '#D7CEA5' }
]
export const hamidashiAllKoObjs = [{ label: '용접 금지', value: 'none', color: '#fff' }, ...hamidashiKoObjs]
export const hamidashiForCatcherKoObjs = hamidashiAllKoObjs

const commonPartsKoObjs = [
  { label: '전체', value: 'all', order: 0 },
  { label: '포구면', value: 'catchFace', order: 1 },
  { label: '웹', value: 'web', order: 2 },
  { label: '바인딩', value: 'edge', order: 4 },
  { label: '엄지 레이스', value: 'thumbHook', order: 5 },
  { label: '소지 레이스', value: 'littleHook', order: 6 },
  { label: '손가락 커버', value: 'indexCover', order: 7 },
  { label: '내피', value: 'linings', order: 8 }
]
const backBeltStyleGloveNoBeltKoObjs = [
  { label: '엄지1', value: 'thumbOut', order: 9 },
  { label: '엄지2', value: 'thumbWeb', order: 10 },
  { label: '검지3', value: 'indexWeb', order: 11 },
  { label: '검지4', value: 'indexMiddle', order: 12 },
  { label: '중지5', value: 'middleIndex', order: 13 },
  { label: '중지6', value: 'middleRing', order: 14 },
  { label: '약지7', value: 'ringMiddle', order: 15 },
  { label: '약지8', value: 'ringLittle', order: 16 },
  { label: '소지9', value: 'littleRing', order: 17 },
  { label: '소지10', value: 'littleOut', order: 18 }
]
const backBeltStyleGloveKoObjs = [...backBeltStyleGloveNoBeltKoObjs, { label: '밴드', value: 'listBelt', order: 19 }]
const backCrownBackGloveKoObjs = [
  { label: '엄지1', value: 'thumbOut', order: 9 },
  { label: '엄지2', value: 'thumbWeb', order: 10 },
  { label: '검지3', value: 'indexWeb', order: 11 },
  { label: '검지4・중지5', value: 'indexLeftMiddleRight', order: 12 },
  { label: '중지6・약지7', value: 'middleLeftRingRight', order: 13 },
  { label: '약지8・소지9', value: 'ringLeftLittleRight', order: 14 },
  { label: '소지10', value: 'littleOut', order: 15 },
  { label: '밴드', value: 'listBelt', order: 16 }
]
const backFirstBackGloveKoObjs = [
  { label: '엄지1', value: 'thumbOut', order: 9 },
  { label: '엄지2・검지・중지5', value: 'thumbIndexMiddle', order: 10 },
  { label: '중지6・약지7', value: 'middleLeftRingRight', order: 11 },
  { label: '약지8・소지9', value: 'ringLeftLittleRight', order: 12 },
  { label: '소지10', value: 'littleOut', order: 13 },
  { label: '밴드', value: 'listBelt', order: 14 }
]
const backGloveUsBackKoObjs = [
  { label: '엄지1', value: 'thumbOut', order: 9 },
  { label: '엄지2', value: 'thumbWeb', order: 10 },
  { label: '엄지근본', value: 'thumbRoot', order: 11 },
  { label: '검지3', value: 'indexWeb', order: 12 },
  { label: '검지4', value: 'indexMiddle', order: 13 },
  { label: '중지5', value: 'middleIndex', order: 14 },
  { label: '중지6', value: 'middleRing', order: 15 },
  { label: '약지7', value: 'ringMiddle', order: 16 },
  { label: '약지8', value: 'ringLittle', order: 17 },
  { label: '소지9', value: 'littleRing', order: 18 },
  { label: '소지10', value: 'littleOut', order: 19 }
]
const backGloveForUBackKoObjs = [
  { label: '엄지1', value: 'thumbOut', order: 9 },
  { label: '엄지2・검지3', value: 'thumbWebAndIndexWeb', order: 10 },
  { label: '검지4', value: 'indexMiddle', order: 11 },
  { label: '중지5', value: 'middleIndex', order: 12 },
  { label: '중지6', value: 'middleRing', order: 13 },
  { label: '약지7', value: 'ringMiddle', order: 14 },
  { label: '약지8', value: 'ringLittle', order: 15 },
  { label: '소지9', value: 'littleRing', order: 16 },
  { label: '소지10', value: 'littleOut', order: 17 },
  { label: '밴드', value: 'listBelt', order: 18 }
]
const backOfRegularBackStyleCatcherMittKoObjs = [
  { label: '엄지・검지・중지5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '중지6・약지', value: 'middleLeftRing', order: 10 },
  { label: '짚다', value: 'indexFingerTipOut', order: 11 },
  { label: '소지10', value: 'littleOut', order: 12 },
  { label: '백스테이지', value: 'fingerStand', order: 13 },
  { label: '밴드', value: 'listBelt', order: 14 }
]
const backOfBeltBackStyleCatcherMittKoObjs = [
  { label: '엄지・검지・중지5', value: 'thumbIndexMiddleRight', order: 9 },
  { label: '중지6・약지・소지', value: 'middleLeftRingLittle', order: 10 },
  { label: '백스테이지', value: 'fingerStand', order: 11 }
]
const backFirstMittKoObjs = [
  { label: '밴드', value: 'listBelt', order: 9 },
  { label: '엄지', value: 'thumb', order: 10 },
  { label: '웹 아래', value: 'underWeb', order: 11 },
  { label: '부메랑', value: 'boomerang', order: 12 }
]
const backFirstMittNoBeltKoObjs = [
  { label: '엄지', value: 'thumb', order: 9 },
  { label: '웹 아래', value: 'underWeb', order: 10 },
  { label: '부메랑', value: 'boomerang', order: 11 }
]
export const catchFacePartsKoObjs = commonPartsKoObjs
export const catcherMittCatchFaceKoObjs = [...commonPartsKoObjs, { label: '타깃', value: 'catchFaceTarget', order: 9 }]
export const gloveFirstBackPartsKoObjs = [...commonPartsKoObjs, ...backFirstBackGloveKoObjs]
export const gloveUsBackPartsKoObjs = [...commonPartsKoObjs, ...backGloveUsBackKoObjs]
export const gloveCrownBackPartsKoObjs = [...commonPartsKoObjs, ...backCrownBackGloveKoObjs]
export const gloveBeltBackPartsKoObjs = [...commonPartsKoObjs, ...backBeltStyleGloveKoObjs]
export const infielderBackPartsForUBackKoObjs = [...commonPartsKoObjs, ...backGloveForUBackKoObjs]
export const uBackKoObjs = [...commonPartsKoObjs, ...backGloveForUBackKoObjs]
export const gloveBeltBackPartsNoBeltKoObjs = [...commonPartsKoObjs, ...backBeltStyleGloveNoBeltKoObjs]
export const catcherMittBackofRegularBackStylePartsKoObjs = [...commonPartsKoObjs, ...backOfRegularBackStyleCatcherMittKoObjs]
export const catcherMittBackofBeltBackStylePartsKoObjs = [...commonPartsKoObjs, ...backOfBeltBackStyleCatcherMittKoObjs]
export const firstMittBackPartsNoBeltKoObjs = [...commonPartsKoObjs, ...backFirstMittNoBeltKoObjs]
export const firstMittBackPartsKoObjs = [...commonPartsKoObjs, ...backFirstMittKoObjs]

export const atomsLabelKoObjs = [
  { label: '블랙 x 골드 x 골드', value: 'ccc', imageUrl: '/atoms/labels/c.jpg' },
  { label: '블랙 x 골드 x 샴페인골드', value: 'ddd', imageUrl: '/atoms/labels/d.jpg' },
  { label: '네이비 x 빨강 x 골드', value: 'eee', imageUrl: '/atoms/labels/e.jpg' },
  { label: '빨강 x 네이비 x 골드', value: 'fff', imageUrl: '/atoms/labels/f.jpg' },
  { label: '블랙 x 골드 x 실버', value: 'ggg', imageUrl: '/atoms/labels/g.jpg' },
  { label: '블루 x 빨강 x 골드', value: 'hhh', imageUrl: '/atoms/labels/h.jpg' },
  { label: '블랙 x 골드 x 블루', value: 'iii', imageUrl: '/atoms/labels/i.jpg' },
  { label: '블랙 x 플라티나 골드 x 골드', value: 'jjj', imageUrl: '/atoms/labels/j.jpg' },
  { label: '블랙 x 블랙 x 블랙', value: 'kkk', imageUrl: '/atoms/labels/k.jpg' },
  { label: '블랙 x 실리콘 골드', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '블랙 x 실리콘 플라티나', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '블랙 x 실리콘 블랙 펄', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '블랙 x 골드 x 핑크', value: 'uuu', imageUrl: '/atoms/labels/u.jpg' },
  { label: '블랙 x 라이트 골드 x 라이트 골드', value: 'yyy', imageUrl: '/atoms/labels/y.jpg' },
  { label: '블랙 x 실리콘 실버', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]

export const atomsERMLabelKoObjs = [
  { label: '블랙 x 실리콘 골드', value: 'lll', imageUrl: '/atoms/labels/l.jpg' },
  { label: '블랙 x 실리콘 플라티나', value: 'mmm', imageUrl: '/atoms/labels/m.jpg' },
  { label: '블랙 x 실리콘 블랙라메', value: 'nnn', imageUrl: '/atoms/labels/n.jpg' },
  { label: '블랙 x 실리콘 실버', value: 'zzz', imageUrl: '/atoms/labels/z.jpg' }
]
export const dispatchKoObjects = {
  linings: liningColorKoObjs,
  listLiningsMaterial: listLiningsMaterialKoObjs,
  all: leatherColorKoObjs,
  catchFace: leatherColorKoObjs,
  web: leatherColorKoObjs,
  web2: leatherColorKoObjs,
  edge: leatherColorKoObjs,
  thumbHook: leatherColorKoObjs,
  thumbWebAndIndexWeb: leatherColorKoObjs,
  littleHook: leatherColorKoObjs,
  indexCover: leatherColorKoObjs,
  thumbOut: leatherColorKoObjs,
  thumbWeb: leatherColorKoObjs,
  thumbRoot: leatherColorKoObjs,
  thumbIndexMiddle: leatherColorKoObjs,
  thumbIndexMiddleRight: leatherColorKoObjs,
  indexWeb: leatherColorKoObjs,
  indexMiddle: leatherColorKoObjs,
  middleIndex: leatherColorKoObjs,
  middleRing: leatherColorKoObjs,
  middleLeftRing: leatherColorKoObjs,
  middleLeftRingRight: leatherColorKoObjs,
  middleLeftRingLittle: leatherColorKoObjs,
  ringMiddle: leatherColorKoObjs,
  ringLittle: leatherColorKoObjs,
  ringLeftLittleRight: leatherColorKoObjs,
  littleRing: leatherColorKoObjs,
  littleOut: leatherColorKoObjs,
  listBelt: leatherColorKoObjs,
  thumb: leatherColorKoObjs,
  boomerang: leatherColorKoObjs,
  underWeb: leatherColorKoObjs,
  catchFaceTarget: leatherColorKoObjs,
  indexLeftMiddleRight: leatherColorKoObjs,
  indexAndMiddle: leatherColorKoObjs,
  fingerStand: leatherColorKoObjs,
  indexFingerTipOut: leatherColorKoObjs
}

export const ko2JaColorPartsTranslates = [
  { korea: '우라가미 가죽', japanese: '浦上レザー' },
  { korea: 'J 코르크', japanese: 'Jコルク' },
  { korea: 'J 블랙', japanese: 'Jブラック' },
  { korea: 'J 레드오렌지', japanese: 'Jレッドオレンジ' },
  { korea: 'J 오렌지', japanese: 'Jオレンジ' },
  { korea: 'U 코르크', japanese: 'Uコルク' },
  { korea: 'U 블랙', japanese: 'Uブラック' },
  { korea: 'U 레드오렌지', japanese: 'Uレッドオレンジ' },
  { korea: 'U 오렌지', japanese: 'Uオレンジ' },
  { korea: 'U C브라운', japanese: 'U Cブラウン' },
  { korea: 'K 코르크', japanese: 'Kコルク' },
  { korea: 'K 블랙', japanese: 'Kブラック' },
  { korea: 'K 레드오렌지', japanese: 'Kレッドオレンジ' },
  { korea: 'K 오렌지', japanese: 'Kオレンジ' },
  { korea: 'K C브라운', japanese: 'K Cブラウン' },
  { korea: '코르크', japanese: 'コルク' }, // ここから以下、明示的に'U' を接頭字として記載
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '레드오렌지', japanese: 'レッドオレンジ' },
  { korea: '오렌지', japanese: 'オレンジ' },
  { korea: 'C브라운', japanese: 'Cブラウン' },
  { korea: 'C 브라운', japanese: 'Cブラウン' },
  { korea: '옐로우', japanese: 'イエロー' },
  { korea: '레몬 옐로우', japanese: 'レモンイエロー' },
  { korea: '레드', japanese: 'レッド' },
  { korea: '핑크', japanese: 'ピンク' },
  { korea: '퍼플', japanese: 'パープル' },
  { korea: '그린', japanese: 'グリーン' },
  { korea: '스카이블루', japanese: 'スカイブルー' },
  { korea: '블루', japanese: 'ブルー' },
  { korea: '네이비', japanese: 'ネイビー' },
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '화이트', japanese: 'ホワイト' },
  { korea: '초콜릿', japanese: 'チョコレート' },
  { korea: '베일러 블루', japanese: 'ベイラーブルー' },
  { korea: '매디글라스', japanese: 'マディグラス' },
  { korea: '틸', japanese: 'ティールグリーン' },
  { korea: '그레이', japanese: 'グレー' },
  // { korea: '코르크', japanese: 'コルク' },
  // { korea: '블랙', japanese: 'ブラック' },
  // { korea: '레드오렌지', japanese: 'レッドオレンジ' },
  // { korea: '오렌지', japanese: 'オレンジ' },
  // { korea: 'C브라운', japanese: ' Cブラウン' },
  // { korea: 'C 브라운', japanese: ' Cブラウン' },
  // { korea: '옐로우', japanese: 'イエロー' },
  // { korea: '레몬 옐로우', japanese: 'レモンイエロー' },
  // { korea: '레드', japanese: 'レッド' },
  // { korea: '핑크', japanese: 'ピンク' },
  // { korea: '퍼플', japanese: 'パープル' },
  // { korea: '그린', japanese: 'グリーン' },
  // { korea: '스카이블루', japanese: 'スカイブルー' },
  // { korea: '블루', japanese: 'ブルー' },
  // { korea: '네이비', japanese: 'ネイビー' },
  // { korea: '블랙', japanese: 'ブラック' },
  // { korea: '화이트', japanese: 'ホワイト' },
  // { korea: '초콜릿', japanese: 'チョコレート' },
  // { korea: '베일러 블루', japanese: 'ベイラーブルー' },
  // { korea: '매디글라스', japanese: 'マディグラス' },
  // { korea: '틸', japanese: 'ティールグリーン' },
  // { korea: '그레이', japanese: 'グレー' },
  // { korea: '사슴 블랙', japanese: 'ディア調ブラック' },
  // { korea: '블랙&화이트', japanese: '中白ブラック' },
  // { korea: '레드&오렌지&화이트', japanese: '中白レッドオレンジ' },
  // { korea: '오렌지&화이트', japanese: '中白オレンジ' },
  // { korea: '스카이 블루', japanese: 'スカイブルー' },
  // { korea: '컷(화이트)', japanese: '切りハミ(ホワイト)' },
  // { korea: '둥근 웰팅(옐로우)', japanese: '玉ハミ(イエロー)' },
  // { korea: '둥근 웰팅(레몬 옐로우)', japanese: '玉ハミ(レモンイエロー)' },
  // { korea: '둥근 웰팅(코르크)', japanese: '玉ハミ(コルク)' },
  // { korea: '둥근 웰팅(초콜릿)', japanese: '玉ハミ(チョコレート)' },
  // { korea: '둥근 웰팅(레드)', japanese: '玉ハミ(レッド)' },
  // { korea: '둥근 웰팅(네이비)', japanese: '玉ハミ(ネイビー)' },
  // { korea: '둥근 웰팅(블루)', japanese: '玉ハミ(ブルー)' },
  // { korea: '둥근 웰팅(블랙)', japanese: '玉ハミ(ブラック)' },
  // { korea: '둥근 웰팅(오렌지)', japanese: '玉ハミ(オレンジ)' },
  // { korea: '둥근 웰팅(레드오렌지)', japanese: '玉ハミ(レッドオレンジ)' },
  // { korea: '둥근 웰팅(베일러 블루)', japanese: '玉ハミ(ベイラーブルー)' },
  // { korea: '둥근 웰팅(매디글라스)', japanese: '玉ハミ(マディグラス)' },
  // { korea: '둥근 웰팅(틸)', japanese: '玉ハミ(ティール)' },
  // { korea: '둥근 웰팅(퍼플)', japanese: '玉ハミ(パープル)' },
  // { korea: '둥근 웰팅(핑크)', japanese: '玉ハミ(ピンク)' },
  // { korea: '둥근 웰팅(화이트)', japanese: '玉ハミ(ホワイト)' },
  // { korea: '둥근 웰팅(그레이)', japanese: '玉ハミ(グレー)' },
  // { korea: '둥근 웰팅(C 브라운)', japanese: '玉ハミ(Cブラウン)' },
  // { korea: '용접 금지', japanese: 'ハミダシなし' },
  // { korea: '양모 (화이트)', japanese: 'ムートン(ホワイト)' },
  // { korea: '양모 (블랙)', japanese: 'ムートン(ブラック)' },
  { korea: '전체', japanese: '全体' },
  { korea: '포구면', japanese: '捕球面' },
  { korea: '웹', japanese: 'ウェブ' },
  { korea: '바인딩', japanese: 'ヘリ革' },
  { korea: '레이스', japanese: 'レース' },
  { korea: '라벨', japanese: 'ラベル' },
  { korea: '스티치', japanese: 'ステッチ' },
  { korea: '웰팅', japanese: 'ハミダシ' },
  { korea: '양모', japanese: 'ムートン' },
  { korea: '엄지 레이스', japanese: '親指掛け紐' },
  { korea: '소지 레이스', japanese: '小指掛け紐' },
  { korea: '손가락 커버', japanese: '指カバー/指当て' }, // old
  { korea: '손가락 커버 및 패드', japanese: '指カバー/指当て' },
  { korea: '내피', japanese: '裏革' },
  { korea: '엄지1', japanese: '親指1' },
  { korea: '엄지2', japanese: '親指2' },
  { korea: '검지3', japanese: '人差指3' },
  { korea: '검지4', japanese: '人差指4' },
  { korea: '중지5', japanese: '中指5' },
  { korea: '중지6', japanese: '中指6' },
  { korea: '약지7', japanese: '薬指7' },
  { korea: '약지8', japanese: '薬指8' },
  { korea: '소지9', japanese: '小指9' },
  { korea: '소지10', japanese: '小指10' },
  { korea: '밴드', japanese: 'バンド' },
  { korea: '검지4・중지5', japanese: '人差指4・中指5' },
  { korea: '중지6・약지7', japanese: '中指6・薬指7' },
  { korea: '약지8・소지9', japanese: '薬指8・小指9' },
  { korea: '엄지2・검지・중지5', japanese: '親指2・人差指・中指5' },
  { korea: '엄지근본', japanese: '親指根本' },
  { korea: '엄지2・검지3', japanese: '親指2・人差指3' },
  { korea: '엄지・검지・중지5', japanese: '親指・人差指・中指5' },
  { korea: '중지6・약지', japanese: '中指6・薬指' },
  { korea: '짚다', japanese: '指だし' },
  { korea: '백스테이지', japanese: '背面台' },
  { korea: '중지6・약지・소지', japanese: '中指6・薬指・小指' },
  { korea: '엄지', japanese: '親指' },
  { korea: '웹 아래', japanese: 'ウェブ下' },
  { korea: '부메랑', japanese: 'ブーメラン' },
  { korea: '타깃', japanese: 'ターゲット' }
  // { korea: '블랙 x 골드 x 골드', japanese: '黒xゴールドxゴールド' },
  // { korea: '블랙 x 골드 x 샴페인골드', japanese: '黒xゴールドxシャンパンゴールド' },
  // { korea: '네이비 x 빨강 x 골드', japanese: 'ネイビーx赤xゴールド' },
  // { korea: '빨강 x 감색 x 골드', japanese: '赤xネイビーxゴールド' },
  // { korea: '블랙 x 골드 x 실버', japanese: '黒xゴールドxシルバー' },
  // { korea: '블루 x 빨강 x 골드', japanese: 'ブルーx赤xゴールド' },
  // { korea: '블랙 x 골드 x 블루', japanese: '黒xゴールドxブルー' },
  // { korea: '블랙 x 플라티나 골드 x 골드', japanese: '黒xプラチナゴールドxゴールド' },
  // { korea: '블랙 x 블랙 x 블랙', japanese: '黒x黒x黒' },
  // { korea: '블랙 x 실리콘 골드', japanese: '黒xシリコンゴールド' },
  // { korea: '블랙 x 실리콘 플라티나', japanese: '黒xシリコンプラチナ' },
  // { korea: '블랙 x 실리콘 블랙 펄', japanese: '黒xシリコンブラックラメ' },
  // { korea: '블랙 x 골드 x 핑크', japanese: '黒xゴールドxピンク' },
  // { korea: '블랙 x 라이트 골드 x 라이트 골드', japanese: '黒xライトゴールドxライトゴールド' },
  // { korea: '블랙 x 실리콘 실버', japanese: '黒xシリコンシルバー' }
]

export const ko2JaColorPartsTranslatesNoTanners = [
  { korea: '코르크', japanese: 'コルク' },
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '레드오렌지', japanese: 'レッドオレンジ' },
  { korea: '오렌지', japanese: 'オレンジ' },
  { korea: 'C브라운', japanese: ' Cブラウン' },
  { korea: 'C 브라운', japanese: ' Cブラウン' },
  { korea: '옐로우', japanese: 'イエロー' },
  { korea: '엘로우', japanese: 'イエロー' },
  { korea: '레몬 옐로우', japanese: 'レモンイエロー' },
  { korea: '레드', japanese: 'レッド' },
  { korea: '핑크', japanese: 'ピンク' },
  { korea: '퍼플', japanese: 'パープル' },
  { korea: '그린', japanese: 'グリーン' },
  { korea: '스카이블루', japanese: 'スカイブルー' },
  { korea: '블루', japanese: 'ブルー' },
  { korea: '네이비', japanese: 'ネイビー' },
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '화이트', japanese: 'ホワイト' },
  { korea: '초콜릿', japanese: 'チョコレート' },
  { korea: '베일러 블루', japanese: 'ベイラーブルー' },
  { korea: '매디글라스', japanese: 'マディグラス' },
  { korea: '틸', japanese: 'ティールグリーン' },
  { korea: '그레이', japanese: 'グレー' },
  { korea: '블랙&화이트', japanese: '中白ブラック' },
  { korea: '레드&오렌지&화이트', japanese: '中白レッドオレンジ' },
  { korea: '오렌지&화이트', japanese: '中白オレンジ' },
  { korea: '스카이 블루', japanese: 'スカイブルー' },
  { korea: '컷(화이트)', japanese: '切りハミ(ホワイト)' },
  { korea: '둥근 웰팅(옐로우)', japanese: '玉ハミ(イエロー)' },
  { korea: '둥근 웰팅(레몬 옐로우)', japanese: '玉ハミ(レモンイエロー)' },
  { korea: '둥근 웰팅(코르크)', japanese: '玉ハミ(コルク)' },
  { korea: '둥근 웰팅(초콜릿)', japanese: '玉ハミ(チョコレート)' },
  { korea: '둥근 웰팅(레드)', japanese: '玉ハミ(レッド)' },
  { korea: '둥근 웰팅(네이비)', japanese: '玉ハミ(ネイビー)' },
  { korea: '둥근 웰팅(블루)', japanese: '玉ハミ(ブルー)' },
  { korea: '둥근 웰팅(블랙)', japanese: '玉ハミ(ブラック)' },
  { korea: '둥근 웰팅(오렌지)', japanese: '玉ハミ(オレンジ)' },
  { korea: '둥근 웰팅(레드오렌지)', japanese: '玉ハミ(レッドオレンジ)' },
  { korea: '둥근 웰팅(베일러 블루)', japanese: '玉ハミ(ベイラーブルー)' },
  { korea: '둥근 웰팅(매디글라스)', japanese: '玉ハミ(マディグラス)' },
  { korea: '둥근 웰팅(틸)', japanese: '玉ハミ(ティール)' },
  { korea: '둥근 웰팅(퍼플)', japanese: '玉ハミ(パープル)' },
  { korea: '둥근 웰팅(핑크)', japanese: '玉ハミ(ピンク)' },
  { korea: '둥근 웰팅(화이트)', japanese: '玉ハミ(ホワイト)' },
  { korea: '둥근 웰팅(그레이)', japanese: '玉ハミ(グレー)' },
  { korea: '둥근 웰팅(C 브라운)', japanese: '玉ハミ(Cブラウン)' },
  { korea: '용접 금지', japanese: 'ハミダシなし' },
  { korea: '양모 (화이트)', japanese: 'ムートン(ホワイト)' },
  { korea: '양모 (블랙)', japanese: 'ムートン(ブラック)' },
  { korea: '화이트', japanese: 'ホワイト' },
  { korea: '블랙', japanese: 'ブラック' },
  { korea: '블랙 x 골드 x 골드', japanese: '黒xゴールドxゴールド' },
  { korea: '블랙 x 골드 x 샴페인골드', japanese: '黒xゴールドxシャンパンゴールド' },
  { korea: '네이비 x 빨강 x 골드', japanese: 'ネイビーx赤xゴールド' },
  { korea: '빨강 x 감색 x 골드', japanese: '赤xネイビーxゴールド' },
  { korea: '블랙 x 골드 x 실버', japanese: '黒xゴールドxシルバー' },
  { korea: '블루 x 빨강 x 골드', japanese: 'ブルーx赤xゴールド' },
  { korea: '블랙 x 골드 x 블루', japanese: '黒xゴールドxブルー' },
  { korea: '블랙 x 플라티나 골드 x 골드', japanese: '黒xプラチナゴールドxゴールド' },
  { korea: '블랙 x 블랙 x 블랙', japanese: '黒x黒x黒' },
  { korea: '블랙 x 실리콘 골드', japanese: '黒xシリコンゴールド' },
  { korea: '블랙 x 실리콘 플라티나', japanese: '黒xシリコンプラチナ' },
  { korea: '블랙 x 실리콘 블랙 펄', japanese: '黒xシリコンブラックラメ' },
  { korea: '블랙 x 골드 x 핑크', japanese: '黒xゴールドxピンク' },
  { korea: '블랙 x 라이트 골드 x 라이트 골드', japanese: '黒xライトゴールドxライトゴールド' },
  { korea: '블랙 x 실리콘 실버', japanese: '黒xシリコンシルバー' }
]
