import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import en from 'src/locales/en'
import ja from 'src/locales/ja'
import ko from 'src/locales/ko'
import { I18NText } from 'src/types'

export const useLocale = (): {
  locale: string
  t: I18NText
  isEn: boolean
  isKo: boolean
  isJa: boolean
  isForeign: boolean
} => {
  // temporary
  const { locale } = useRouter()
  const isEn = locale === 'en'
  const isKo = locale === 'ko'
  const isJa = locale === 'ja'
  const isForeign = isEn || isKo
  const t = isEn ? en : isKo ? ko : ja
  return { locale, t, isEn, isKo, isJa, isForeign }
}

export const useTransition = (modelPath: 'us' | 'korea'): { isFirstRendered: boolean } => {
  // const isFirstRendered = true
  const [isFirstRendered, setIsFirstRendered] = useState(false)
  const { locale, pathname, replace } = useRouter()

  useEffect(() => {
    const isInvalidSimulation = locale === 'ja' && !!modelPath && pathname.split('/').includes(modelPath)
    if (isInvalidSimulation) replace('/hard/atoms/japan')
    setIsFirstRendered(true)
  }, [])
  return { isFirstRendered }
}
